var App = {
    $body:$("body"),

    init: function init() {
        this.$App = $("#App");
        this.selectedLang = "fr";

        var Utils = require("utils");
        this.utils = new Utils();

        var HeaderView = require("views/headerView");
        this.headerView = new HeaderView();

        var FooterView = require("views/footerView");
        this.footerView = new FooterView();

        var Router = require("router");
        this.router = new Router();

        Backbone.history.start();
    },

    updateFromRouter:function(){
        var self = this;
        this.utils.gatherData(function(){
            self.render();
        });
    },

    render:function(){
        var self = this;

    	this.utils.fillTranslations();
    	this.headerView.render();
        this.footerView.render();
    	this.bindEvents();
        this.animateHome();
    },

    animateHome:function(){
        var self = this;

        $("#Header").attr("data-state","splash");
        setTimeout(function(){
            $("#Header").addClass("transitionnable");
            setTimeout(function(){
                $("#homeheaderlogoContainer").addClass("reveal");
                setTimeout(function(){
                    $("#homepageTitle").addClass("reveal");
                    setTimeout(function(){
                        $("#homepageTitle").addClass("reveal");
                        setTimeout(function(){
                            $("#homeimage").addClass("reveal");
                            setTimeout(function(){
                                $(".home_launchbt").addClass("reveal");
                            },800)
                        },300)
                    },300)
                },300)
            },300)
        },10)
        

    },

    bindEvents:function(){
    	var self = this;

    	$(".home_launchbt").on("click", function(){
    		self.changeHome();
    	});

    	$(".intro_bt_link").on("click", function(){
            var obj = self.utils.getRandomStarId();
            location.href = "story.php#"+App.selectedLang+"/"+obj;
    	});

        $(".home_explore_link").on("click",function(){
            location.href = "explore.php#"+App.selectedLang;
        });

       $("#App").on("DOMMouseScroll mousewheel wheel", function(e){
            self.changeHome();
        });

    },

    changeHome:function(){
        var self =this;
        self.$App.attr("data-state", "intro");
            $("#Header").attr("data-state","intro");
            setTimeout(function(){
                $("#homeimage").addClass("mask");
                $(".home_launchbt").css("display","none");
                setTimeout(function(){
                    self.$App.attr("data-animation", "second");
                    setTimeout(function(){
                        $(".home_discoverbt").addClass("reveal");
                        setTimeout(function(){
                            $(".home_exploreWraper").addClass("reveal");
                        },500)
                    },1000)
                },600);
            },600)
    },

};

module.exports = App;
window.App = App;