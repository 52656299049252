var ParcoursTimelineView = Backbone.View.extend({

    initialize:function(){
        var self = this;

        this.selectedTab = "creation";

        this.defaultSliderParams = {
            "creation":{ min:0, max: 500, values:[undefined, undefined], timestampValues: [undefined, undefined], faxeMax: false },
            "decouverte":{ min:0, max: 500, values:[undefined, undefined], timestampValues: [undefined, undefined], faxeMax: false },
            "vente":{ min:0, max: 500, values:[undefined, undefined], timestampValues: [undefined, undefined], faxeMax: false }
        };
        this.sliderParams = JSON.parse(JSON.stringify(this.defaultSliderParams));

        this.$ParcoursTimeline = $("#ParcoursTimeline");
        this.$ParcoursTimelineSlider = $( "#ParcoursTimelineSlider" );
        this.$timelineResetbt = $(".timeline_resetbt");

        this.$trackBegin = $(".track_bound.begin");
        this.$trackEnd = $(".track_bound.end");
    },

    render:function(){
        var self = this;

        this.$ParcoursTimelineSlider.slider({
            range: true,
            min: 0,
            max: 500,
            values: [ 75, 300 ],
            stop: function( event, ui ) {
                self.slideSlider(ui);
            }
        });
        this.bindEvents();
    },

    reinitSliderParams: function () {
        this.sliderParams = JSON.parse(JSON.stringify(this.defaultSliderParams));
    },

    update: function () {
        var self = this;
        
        this.updateTab();
        this.updateSliderValues();
    },

    updateTab: function () {
        this.$ParcoursTimeline.find(".timeline_tab").removeClass("selected")
                                                    .removeClass("disabled");
        this.$ParcoursTimeline.find(".timeline_tab[data-tab='"+this.selectedTab+"']").addClass("selected");
        this.updateSliderParams();
    },

    updateSliderParams: function () {
        var self = this;

        _.each(["creation", "decouverte"], function (type){
            var param = "date_creation_debut_ts";
            if(type == "decouverte") param = "date_decouverte_debut_ts";

            var allDates = _.map(App.filteredObjetsBeforeTimeline, function (o){
                return o[param];
            });

            var maxObjDate = _.max(App.filteredObjetsBeforeTimeline, function (o){
                if(o[param] == null) return - 100000000000000000000;
                return o[param];
            });

            allDates = _.without(allDates, null);

            if(allDates.length > 0) {
                var minDate = _.min(allDates);
                var dMin = new Date(minDate * 1000);
                var minYear = dMin.getFullYear();

                var maxDate = _.max(allDates);
                var dMax = new Date(maxDate * 1000);
                var maxYear = dMax.getFullYear();

                var oldMin = self.sliderParams[type].min;
                var oldMax = self.sliderParams[type].max;

                self.sliderParams[type].fakeMax = false;
                if(minYear == maxYear){
                    maxYear += 1;
                    self.sliderParams[type].fakeMax = true;
                }

                if(oldMin !== minYear || oldMax !== maxYear) {
                    self.sliderParams[type].values[minYear, maxYear];
                }

                self.sliderParams[type].min = minYear;
                self.sliderParams[type].max = maxYear;
            } else {
                self.sliderParams[type].min = undefined;
                self.sliderParams[type].max = undefined;
                self.sliderParams[type].values[undefined, undefined];
                self.$ParcoursTimeline.find(".timeline_tab[data-tab='"+type+"']").addClass("disabled");
            }

            
        });


        //ventes dates
        var allVentes = _.map(App.filteredObjetsBeforeTimeline, function (o){ return o.sortedVentes; })
        allVentes = _.flatten(allVentes);
        var groupedVentes = _.groupBy(allVentes, function (o) { return o.vente_nom; })
        var uniqVentesNoms = _.keys(groupedVentes);

        console.log("uniqVentesNoms", uniqVentesNoms);
        var allDatesVentes = []
        _.each(uniqVentesNoms, function (venteNom){
            allDatesVentes.push(App.uniqVentes[venteNom][0].vente_date_ts);
        })

        console.log("allDatesVentes", allDatesVentes);

        if(allDatesVentes.length > 0){
            var minDateVente = _.min(allDatesVentes);
            var dMinVente = new Date(minDateVente * 1000);
            var minYearVente = dMinVente.getFullYear();
            console.log("minDateVente", minDateVente);
            console.log("minYearVente", minYearVente);

            var maxDateVente = _.max(allDatesVentes);
            var dMaxVente = new Date(maxDateVente * 1000);
            var maxYearVente = dMaxVente.getFullYear();
            console.log("maxDateVente", maxDateVente);
            console.log("maxYearVente", maxYearVente);

            var oldMinVente = self.sliderParams.vente.min;
            var oldMaxVente = self.sliderParams.vente.max;

            self.sliderParams.vente.fakeMax = false;
            if(minYearVente == maxYearVente){
                maxYearVente += 1;
                self.sliderParams.vente.fakeMax = true;
            }

            if(oldMinVente !== minYearVente || oldMaxVente !== maxYearVente) {
                self.sliderParams.vente.values[minYearVente, maxYearVente];
            }

            this.sliderParams.vente.min = minYearVente;
            this.sliderParams.vente.max = maxYearVente;
        } else {
            self.sliderParams.vente.min = undefined;
            self.sliderParams.vente.max = undefined;
            self.sliderParams.vente.values[undefined, undefined];
            self.$ParcoursTimeline.find(".timeline_tab[data-tab='vente']").addClass("disabled");
        }


        this.$ParcoursTimelineSlider.slider("option", "min", this.sliderParams[this.selectedTab].min);
        this.$ParcoursTimelineSlider.slider("option", "max", this.sliderParams[this.selectedTab].max);
    },

    updateSliderValues: function () {
        var self = this;
        var selectedMinValue = 0;
        var selectedMaxValue = 0;

        console.log("updateSliderValues", this.selectedTab);

        if(this.sliderParams[this.selectedTab].values[0] == undefined) {
            selectedMinValue = this.sliderParams[this.selectedTab].min;
            selectedMaxValue = this.sliderParams[this.selectedTab].max;
        } else {
            selectedMinValue = this.sliderParams[this.selectedTab].values[0];
            selectedMaxValue = this.sliderParams[this.selectedTab].values[1];
        }

        console.log("selectedMinValue", selectedMinValue)
        console.log("selectedMaxValue", selectedMaxValue)

        this.$trackBegin.html(selectedMinValue);

        if(this.sliderParams[this.selectedTab].fakeMax) this.$trackEnd.html(selectedMaxValue - 1);
        else this.$trackEnd.html(selectedMaxValue);

        this.$ParcoursTimelineSlider.slider("values", 0, selectedMinValue);
        this.$ParcoursTimelineSlider.slider("values", 1, selectedMaxValue);

        this.sliderParams[this.selectedTab].values[0] = selectedMinValue;
        this.sliderParams[this.selectedTab].values[1] = selectedMaxValue;

        this.sliderParams[this.selectedTab].timestampValues[0] = App.utils.getTimestampFromYear(String(selectedMinValue));
        this.sliderParams[this.selectedTab].timestampValues[1] = App.utils.getTimestampFromYear(String(selectedMaxValue));

        $(".timeline_tab").removeClass("modified");
        this.$ParcoursTimeline.removeClass("modified");
        _.each(this.sliderParams, function (o, tabId){
            if(!isNaN(o.min) && o.values[0] !== null && (o.values[0] != o.min || o.values[1] != o.max)){
                $(".timeline_tab[data-tab='"+tabId+"']").addClass("modified");
                self.$ParcoursTimeline.addClass("modified");
            }  
        })
    },

    slideSlider: function (sliderObj) {
        this.sliderParams[this.selectedTab].values = sliderObj.values;
        this.sliderParams[this.selectedTab].timestampValues[0] = App.utils.getTimestampFromYear(String(sliderObj.values[0]));
        this.sliderParams[this.selectedTab].timestampValues[1] = App.utils.getTimestampFromYear(String(sliderObj.values[1]));
        App.update();
    },

    bindEvents:function(){
        var self = this

        this.$ParcoursTimeline.find(".timeline_tab").on("click", function (){
            var $tab = $(this);
            if(!$tab.hasClass("disabled")){
                self.selectTab($tab);
            }
        })

        $(".track_legend").on("click", function (){
            self.displayTimelineModal();
        })

        this.$timelineResetbt.on("click", function (){
            self.resetTimeline();
        })
    },

    selectTab: function ($tab) {
        var dataTab = $tab.attr("data-tab");
        this.selectedTab = dataTab;
        this.updateTab();
        this.updateSliderValues();
    },

    resetTimeline: function () {
        _.each(this.sliderParams, function (o, tabId){
            o.values[0] = o.min;
            o.values[1] = o.max;
        })
        App.update();
    },

    displayTimelineModal: function () {
        $("#TimelineModal").addClass("show");
    }
});

module.exports = ParcoursTimelineView;