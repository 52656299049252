var App = {
    $body:$("body"),

    init: function init() {
        this.$App = $("#App");
        this.$Mapper = $("#mapper");
        this.isFirstUpdate = true;
        this.seenDescriptive = false;
        this.selectedLang = "fr";
        this.objID = "";
        this.stepID = "";
        this.stepVenteID = "";
        this.stepVenteInc = 0;
        this.ventesIDS = [];
        this.steps = {};
        this.stepsVentes = {};
        this.isLoaded = false;
        this.scrollingInc = 0;
        this.isScrollable = true;
        this.introTimeout = 1000;

        var Utils = require("utils");
        this.utils = new Utils();

        var HeaderView = require("views/headerView");
        this.headerView = new HeaderView();

        var FooterView = require("views/footerView");
        this.footerView = new FooterView();

        var StoryMapView = require("views/story/storyMapView");
        this.storyMapView = new StoryMapView();

        var StoryTimelineView = require("views/story/storyTimelineView");
        this.storyTimelineView = new StoryTimelineView();

        var StorySidebarView = require("views/story/storySidebarView");
        this.storySidebarView = new StorySidebarView();

        var Router = require("routerStory");
        this.router = new Router();

        this.gatherData();
    },

    gatherData:function(){
        this.utils.gatherData(function(){
            Backbone.history.start();
        });
    },

    updateFromRouter:function(){
        var self = this;
        if(this.isLoaded) location.reload();
        else{
            this.isLoaded = true;
            this.render();
        }
    },

    render:function(){
        if(App.utils.objets[App.objID].classement_objet == "1"){
            if(App.selectedLang=="fr"){
                $(".menuDescriptive[data-step='creation'] .descriptive_text").html(App.utils.objets[App.objID].texte_creation);
                $(".menuDescriptive[data-step='decouverte'] .descriptive_text").html(App.utils.objets[App.objID].texte_decouverte);
                $(".menuDescriptive[data-step='ventes'] .descriptive_text").html(App.utils.objets[App.objID].texte_ventes);
                $(".menuDescriptive[data-step='conservation'] .descriptive_text").html(App.utils.objets[App.objID].texte_conservation);
            }else{
                $(".menuDescriptive[data-step='creation'] .descriptive_text").html(App.utils.objets[App.objID].texte_creation_en);
                $(".menuDescriptive[data-step='decouverte'] .descriptive_text").html(App.utils.objets[App.objID].texte_decouverte_en);
                $(".menuDescriptive[data-step='ventes'] .descriptive_text").html(App.utils.objets[App.objID].texte_ventes_en);
                $(".menuDescriptive[data-step='conservation'] .descriptive_text").html(App.utils.objets[App.objID].texte_conservation_en);
            }
        }
    	this.utils.fillTranslations();
        this.computeSteps();
    	this.headerView.render();
        this.footerView.render();
        this.bindEvents();
        this.initializeSidebar();
        this.prepareVenteList();
        this.storyMapView.render();
        this.storyTimelineView.render();
        this.storySidebarView.render();
        this.animateIntro();
        this.update();
    },

    animateIntro:function(){
        var self = this;
        this.$App.addClass("unfaded");
        setTimeout(function(){
            self.$App.addClass("fadenodisplayie");
        }, 400);
        setTimeout(function(){
            $("#StoryScroll").addClass("displayed");
        }, App.introTimeout);
    },

    bindEvents:function(){
    	var self = this;
        $(".menuCase").on("click",function(){
            var stepID = $(this).attr("data-step");
            self.changeStep(stepID);
        });

        $("#nextStepBtn").on("click",function(){
            console.log("click nextstepbtn");
            self.nextStep();
        });

        $(".descriptive_togglebt").on("click", function(){
            $(".menuDescriptive.displayed").toggleClass("shrinked");
        });

        $(window).on("resize", function(){
            location.reload();
        });

        this.$Mapper.on("DOMMouseScroll mousewheel wheel", function(e){
            self.onMouseWheelSpin(e);
        });

        var swip = new Hammer(this.$Mapper[0]);
        swip.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL });

        swip.on("swipedown", function(e) {
            if(App.isScrollable){
                self.incrementScrolling("up");
            }
        });

        swip.on("swipeup", function(e) {
            if(App.isScrollable){
                self.incrementScrolling("down");
            }
        });
    },

    onMouseWheelSpin:function(e){
        var self = this;
        e.preventDefault();
        if(e.deltaY <= -1){
            if(App.isScrollable){
                App.isScrollable = false;
                self.incrementScrolling("down");
                setTimeout(function(){
                    App.isScrollable = true;
                },1500);
            }
        }
        else if(e.deltaY > 1){
            if(App.isScrollable){
                App.isScrollable = false;
                self.incrementScrolling("up");
                setTimeout(function(){
                    App.isScrollable = true;
                },1500);
            }
        }
    },

    incrementScrolling:function(way){
        var self = this;
        this.updateStepFromScrolling(way);
    },

    updateStepFromScrolling:function(way){
        var self = this;
        if(way == "down"){
            this.nextStep();
        }else{
            this.prevStep();
        }
    },

    initializeSidebar:function(){
        var self = this;
        
        var o = App.utils.objets[this.objID];
        objImg = o.media;
        console.log("objImg", objImg)
        //$("#imageContainer").html("<img src='"+objImg+"' />)");
        
        App.utils.imageExists2(o.image_exists, function(imgExists){
            console.log("imgExists", imgExists);
            if(imgExists){
                $("#imageContainer").html("<img src='"+objImg+"' />");
            }else{
                var defaultImage = "img/collection/default/"+_.str.slugify(o.categorie_picto)+"-";
                if(o.matiere_filtre !== null && o.matiere_filtre !== ""){
                    defaultImage += (_.str.slugify(o.matiere_filtre) + ".svg");
                }else{
                    defaultImage += "defaut.svg";
                }
                $("#imageContainer").html("<img src='"+defaultImage+"' />");
            }
        });
    },

    update:function(){
        if(this.isFirstUpdate){
            this.isFirstUpdate = false;
        }else{
            $("#StoryScroll").removeClass("displayed");
        }
        this.stepVenteID = App.utils.objets[this.objID].sortedVentes[this.stepVenteInc].venteStep;
        this.router.updateRoute();
        this.updateSidebar();
        this.updateMenu();
        this.storyTimelineView.updateMiniTimeline();
        this.storyTimelineView.updateTimeline();
        this.updateNextBtn();

        if(App.utils.objets[App.objID].classement_objet == "1"){
            console.log("steps", App.stepID, App.stepVenteInc);
            if(App.stepID == "ventes" && App.stepVenteInc > 0){

            }else{
                $(".menuDescriptive").removeClass("displayed")
                                    .removeClass("shrinked");
            }
            setTimeout(function(){
                $(".menuDescriptive[data-step='"+App.stepID+"']").addClass("displayed");
            },  App.introTimeout);
            this.seenDescriptive = true;
        }else{
            $(".menuDescriptive").removeClass("displayed");
        }

        if(App.stepID == "conservation"){
            if($(".nextblock_bt").attr("data-status") !== "finalstep"){
                setTimeout(function(){
                    $("#conservationNextblock .nextblock_bt").attr("data-status", "transitioning");
                }, 3000);
                setTimeout(function(){
                    $("#conservationNextblock .nextblock_bt").attr("data-status", "finalstep");
                }, 9000);
            }
        }
        $("#mapper").attr("data-step", App.stepID)
                    .attr("data-stepvente", App.stepVenteID);

        this.storyMapView.updateMap();
    },

    updateSidebar:function(){
        var self = this;
        actualStep = this.stepID;
        switch(actualStep){
            case("creation"):
                this.storySidebarView.gatherCreation();
                break;
            case("decouverte"):
                this.storySidebarView.gatherDecouverte();
                break;
            case("ventes"):
                this.storySidebarView.gatherVentes();
                break;
            case("conservation"):
                this.storySidebarView.gatherConservation();
                break;
        }

    },

    changeStep:function(stepID){
        var self = this;
        console.log("changeStep", stepID, this.stepID);
        if(this.stepID !== stepID){
            this.stepID = stepID;
            this.stepVenteInc = 0;
            this.update();
        }
    },

    updateNextBtn:function(){
        actualStep = this.stepID;
        $("#nextStepBtn").removeClass("hide")
        switch(actualStep){
            case("creation"):
                $("#nextStepBtnLabel").html(App.utils.translations["vers-la-decouverte"][App.selectedLang]);
                break;
            case("decouverte"):
                $("#nextStepBtnLabel").html(App.utils.translations["vers-les-ventes"][App.selectedLang]);
                break;
            case("ventes"):
                var currentStepVenteInc = parseInt(App.stepVenteID.split("_")[1], 10);
                if(this.stepVenteInc < (this.ventesIDS.length - 1)){
                    $("#nextStepBtnLabel").html(App.utils.translations["vers-vente-suivante"][App.selectedLang]);
                }
                else{
                    $("#nextStepBtnLabel").html(App.utils.translations["vers-la-conservation"][App.selectedLang]);
                }
                break;
            case("conservation"):
                $("#nextStepBtn").addClass("hide");
                break;
        }
    },

    nextStep:function(){
        var self=this;
        actualStep = this.stepID;

        switch(actualStep){
            case("creation"):
                this.stepID = "decouverte";
                self.update();
                break;
            case("decouverte"):
                this.stepID = "ventes";
                self.update();
                break;
            case("ventes"):
                if(this.stepVenteInc < (this.ventesIDS.length - 1)){
                    this.stepVenteInc = this.stepVenteInc + 1;
                } else{
                    this.stepID = "conservation";
                }
                self.update();
                break;
        }
    },

    prevStep:function(){
        var self=this;
        actualStep = this.stepID;

        switch(actualStep){
            case("decouverte"):
                this.stepID = "creation";
                self.update();
                break;
            case("conservation"):
                this.stepVenteInc = this.ventesIDS.length - 1;
                this.stepID = "ventes";
                self.update();
                break;
            case("ventes"):
                if(this.stepVenteInc > 0){
                    this.stepVenteInc = this.stepVenteInc - 1;
                } else{
                    this.stepID = "decouverte";
                }
                self.update();
                break;
        }
    },

    prepareVenteList:function(){
        var self = this;
        self.ventesIDS = [];
        var sortedVentes = App.utils.objets[App.objID].sortedVentes;
        _.each(sortedVentes,function(vente,i){
            vente.inc = i;
            self.ventesIDS.push(vente.id_appvente);
        });
    },

    updateMenu:function(){
        var self = this;
        $(".menuCase").removeClass("active");
        $(".menuCase[data-step='"+this.stepID+"']").addClass("active");

        var nodataID = App.stepID;
        if(nodataID == "ventes") nodataID = "vente_1";
        $(".menuCase_nodata").removeClass("displayed");
        if(!App.steps[nodataID].isAvailable){
            $(".menuCase[data-step='"+this.stepID+"'] .menuCase_nodata").addClass("displayed");
        }
    },

    //compute object steps
    computeSteps:function(){
        var self = this;
        this.steps["creation"] = this.getStepData("creation");
        this.steps["creation"].inc = 0;
        this.steps["decouverte"] = this.getStepData("decouverte");
        this.steps["decouverte"].inc = 1;

        var numVente = 0;
        var incVente = 0;
        var currentVenteVille = "";

        _.each(App.utils.objets[App.objID].sortedVentes, function(v){
            if(currentVenteVille !== v.vente_ville){
                numVente += 1;
                currentVenteVille = v.vente_ville;
                var venteStep = "vente_"+numVente;
                var isAvailable = true;
                if(v.vente_lat == "") isAvailable = false;
                self.steps[venteStep] = {
                    id:venteStep,
                    inc:numVente+1,
                    name:venteStep,
                    isAvailable:isAvailable,
                    location:v.vente_ville,
                    className:"vente "+venteStep,
                    lat:v.vente_lat,
                    lon:v.vente_long,
                    corolle:false,
                    subventes:[{
                        venteID:v.id_appvente,
                    }]
                };
            }else{
                var venteStep = "vente_"+numVente;
                self.steps[venteStep].corolle = true;
                self.steps[venteStep].subventes.push({
                    venteID:v.id_appvente,
                });
            }
            v.venteStep = venteStep;
            incVente += 1;
        });

        this.stepsVentes = _.filter(this.steps, function(s){
            return s.id.split("vente").length > 1;
        });

        this.steps["conservation"] = this.getStepData("conservation");
        this.steps["conservation"].inc = numVente + 2;
    },

    getStepData:function(stepID){
        var locationName = App.utils.objets[App.objID]["lieu_"+stepID+"_nom"];
        if(stepID == "conservation") locationName = App.utils.objets[App.objID]["conservation_ville"];

        var isAvailable = true;
        if(App.utils.objets[App.objID]["lieu_"+stepID+"_lat"] == "") isAvailable = false;

        return {
            id:stepID,
            name:stepID,
            location:locationName,
            isAvailable:isAvailable,
            lat:App.utils.objets[App.objID]["lieu_"+stepID+"_lat"],
            lon:App.utils.objets[App.objID]["lieu_"+stepID+"_long"],
            className:stepID
        }
    },

    getNextStepID:function(stepID){

    }

};

module.exports = App;
window.App = App;