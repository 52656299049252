var App = {
    $body:$("body"),

    init: function init() {
        this.$App = $("#App");
        this.$WrapperDataviz = $(".wrapper_dataviz");
        this.selectedLang = "fr";
        this.coulissesVentes = [];

        var Utils = require("utils");
        this.utils = new Utils();

        var HeaderView = require("views/headerView");
        this.headerView = new HeaderView();

        var FooterView = require("views/footerView");
        this.footerView = new FooterView();

        var Router = require("router");
        this.router = new Router();

        Backbone.history.start();
    },

    updateFromRouter:function(){
        var self = this;
        console.log("updateFromRouter");
        this.utils.gatherData(function(){
            self.gatherCoulissesData();
        });
    },

    gatherCoulissesData:function(){
        var self = this;

        d3.csv("data/inha-coulisses-ventes.csv")
        .then(function(arrayData){
            _.each(arrayData, function (data){
                self.coulissesVentes.push({
                    name:data.Intitules,
                    date:data.Date_debut,
                    saisie:data.Etat_avancement_saisie
                });
            })
            self.render();
        })
        .catch(function(error){
            console.log(error, rows);
        })
        /*
        d3.csv("data/inha-coulisses-ventes.csv", function(data){
            console.log(data);
            self.coulissesVentes.push({
                name:data.Intitules,
                date:data.Date_debut,
                saisie:data.Etat_avancement_saisie
            });
        }, function(error, rows) {
            
        });
        */
    },

    render:function(){
    	console.log("render app ?");
    	this.utils.fillTranslations();
        this.sortCoulissesVentes();
    	this.headerView.render();
        this.footerView.render();
    	this.bindEvents();
        this.utils.displayMenuActiveBtn("backstage");
        this.fillDataviz();
    },

    sortCoulissesVentes:function(){
        this.sortedCoulissesVentes = _.sortBy(this.coulissesVentes, function(cv){
            return App.utils.getTimestampFromLiteralDateFR(cv.date);
        });
    },

    fillDataviz:function(){
        var self = this;

        var incFilled = 0;
        _.each(this.sortedCoulissesVentes, function(cv){
            var $square = $("<div class='dataviz_square' data-date='"+cv.date+"'></div>");
            if(cv.saisie == "Publiée"){
                incFilled += 1;
                $square.addClass("filled");
            }
            self.$WrapperDataviz.append($square);
        });

        
        console.log(App.utils.translations["ventes"][App.selectedLang])
        $(".text_nbventes").html(this.sortedCoulissesVentes.length+" "+App.utils.translations["ventes"][App.selectedLang]);
        $(".text_nbventes_saisies").html(App.utils.translations["dont"][App.selectedLang]+" "+incFilled+" "+App.utils.translations["traitees"][App.selectedLang])
    },

    bindEvents:function(){
    	var self = this;
    }

};

module.exports = App;
window.App = App;