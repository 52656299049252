var App = {
    $body:$("body"),

    init: function init() {
        this.$App = $("#App");
        this.selectedLang = "fr";
        this.isFirstUpdate = true;
        this.forceFeatured = false;
        this.cancelTuto = false;
        this.isReloadingMap = false;

        var Utils = require("utils");
        this.utils = new Utils();

        var HeaderView = require("views/headerView");
        this.headerView = new HeaderView();

        var FooterView = require("views/footerView");
        this.footerView = new FooterView();

        var ParcoursSidebarView = require("views/parcours/parcoursSidebarView");
        this.parcoursSidebarView = new ParcoursSidebarView();

        var ParcoursMapView = require("views/parcours/parcoursMapView");
        this.parcoursMapView = new ParcoursMapView();

        var ParcoursTimelineView = require("views/parcours/parcoursTimelineView");
        this.parcoursTimelineView = new ParcoursTimelineView();

        var ParcoursTutoView = require("views/parcours/parcoursTutoView");
        this.parcoursTutoView = new ParcoursTutoView();


        var Router = require("routerParcours");
        this.router = new Router();

        this.$parcoursNb = $(".parcours_nb");
        this.$parcoursFiltres = $(".parcours_filtres");
        this.$ParcoursAwaitingLayer = $("#ParcoursAwaitingLayer");
        this.$CancelTutoLayer = $("#CancelTutoLayer");
        this.$ParcoursTitle = $("#ParcoursTitle");

        this.uniqVentes = {};
        this.uniqActeurs = {};

        this.defaultSelectedItems = {
            "types":["tous"],
            "matieres":["toutes"],
            "locations": {
                "toutes": [],
                "creation": [],
                "decouverte": [],
                "conservation": []
            },
            "ventes": ["toutes"],
            "acteurs": ["tous"]
        };

        this.selectedItems = JSON.parse(JSON.stringify(this.defaultSelectedItems));
        this.allLocationsMode = true;
        this.objetsWithLocation = [];
        this.filteredObjets = [];
        this.filteredParcours = [];
        this.groupedRealParcours = {};
        this.groupedSimilarParcours = {};

        this.availableLocations = {
            "creation":[],
            "decouverte":[],
            "conservation":[],
            "ventes":[]
        }

        this.$SaveInput = $("#SaveInput");
        this.$SaveIndic = $("#SaveIndic");
        this.parcoursSaveURL = "";

        this.loadApp();
    },

    loadApp: function () {
        var self = this;

        if(PARCOURSID != ""){
            this.utils.gatherParcoursSummaryData(function (){
                self.checkParcoursURL();
                var findFeaturedParcours = _.find(App.utils.parcoursFeaturedItems, function (o) { return o.parcoursId == PARCOURSID });
                if(findFeaturedParcours !== undefined) {
                    self.$ParcoursTitle.html(findFeaturedParcours.title)
                                        .addClass("displayed");
                }
            });
        } else{
            this.gatherData();
        }
    },

    checkParcoursURL: function () {
        var self = this;
        this.utils.getParcoursParams(PARCOURSID, function (response){
            var JSONResponse = JSON.parse(response);
            var params = JSON.parse(JSONResponse.params);
            self.selectedItems = params;
            self.forceFeatured = true;
            self.parcoursTutoView.tutoSeen = true;

            if(self.selectedItems.locations.conservation.length > 0 || self.selectedItems.locations.creation.length > 0 || self.selectedItems.locations.decouverte.length > 0) self.allLocationsMode = false;
            self.gatherData();
        });
    },

    gatherData:function(){
        this.utils.gatherData(function(){
            Backbone.history.start();
        });
    },

    updateFromRouter:function(){
        var self = this;
        this.processData();
    },

    processData: function () {
        var self = this;

        this.uniqVentes = _.groupBy(App.utils.ventes, function (o){
            return o.vente_nom;
        });

        this.objetsWithLocation = _.filter(App.utils.objets, function (obj){
            var hasParcours = false;
            obj.parcoursCreaDec = false;
            obj.parcoursDecCons = false;
            obj.parcoursCreaCons = false;
            obj.parcoursDecVente = false;
            obj.parcoursCreaVente = false;
            obj.parcoursVenteCons = false;
            obj.parcoursVenteVente = false;

            if(obj.lieu_creation_lat !== "" && obj.lieu_creation_long !== "" && obj.lieu_creation_lat !== undefined && obj.lieu_creation_long !== undefined){
                if(obj.lieu_decouverte_lat !== "" && obj.lieu_decouverte_long !== "" && obj.lieu_decouverte_lat !== undefined && obj.lieu_decouverte_long !== undefined) {
                    obj.parcoursCreaDec = true;
                    hasParcours = true;
                    if(obj.lieu_conservation_lat !== "" && obj.lieu_conservation_long !== "" && obj.lieu_conservation_lat !== undefined && obj.lieu_conservation_long !== undefined) {
                        obj.parcoursDecCons = true;
                    }
                } else {
                    if(obj.lieu_conservation_lat !== "" && obj.lieu_conservation_long !== "" && obj.lieu_conservation_lat !== undefined && obj.lieu_conservation_long !== undefined) {
                        obj.parcoursCreaCons = true;
                        hasParcours = true;
                    }
                }
            } else {
                if(obj.lieu_decouverte_lat !== "" && obj.lieu_decouverte_long !== "" && obj.lieu_conservation_lat !== "" && obj.lieu_conservation_long !== "" && obj.lieu_decouverte_lat !== undefined && obj.lieu_decouverte_long !== undefined && obj.lieu_conservation_lat !== undefined && obj.lieu_conservation_long !== undefined) {
                    obj.parcoursDecCons = true; 
                    hasParcours = true;  
                }
            }

            var nbVentes = obj.sortedVentes.length;
            var numVente = 1;

            _.each(obj.ventes, function (venteObj, venteId){
                if(numVente == 1) {
                    //begin
                    if(obj.lieu_decouverte_lat !== "" && obj.lieu_decouverte_long !== "" && obj.lieu_decouverte_lat !== undefined && obj.lieu_decouverte_long !== undefined) {
                        obj.parcoursDecVente = true;
                        obj.parcoursDecCons = false;
                        hasParcours = true;
                    } else if(obj.lieu_creation_lat !== "" && obj.lieu_creation_long !== "" && obj.lieu_creation_lat !== undefined && obj.lieu_creation_long !== undefined) {
                        obj.parcoursCreaVente = true;
                        obj.parcoursCreaCons = false;
                        hasParcours = true;
                    }

                    //end
                    if(nbVentes == 1 && obj.lieu_conservation_lat !== "" && obj.lieu_conservation_long !== "" && obj.lieu_conservation_lat !== undefined && obj.lieu_conservation_long !== undefined) {
                        obj.parcoursVenteCons = true;
                        hasParcours = true;
                    }
                } else {
                    obj.parcoursVenteVente = true;
                    hasParcours = true;
                    if(obj.lieu_conservation_lat !== "" && obj.lieu_conservation_long !== "" && obj.lieu_conservation_lat !== undefined && obj.lieu_conservation_long !== undefined) {
                        obj.parcoursVenteCons = true;
                    }
                }
                numVente += 1;
            })

            //if(!hasParcours) console.log(obj);
            return hasParcours;
        })

        console.log("objetsWithLocation", this.objetsWithLocation);

        var availableObjectsIds = _.map(this.objetsWithLocation, function (ao) { return ao.id_objet });
        var availableActeurs = _.filter(App.utils.acteurs, function (objActeur){
            return _.intersection(objActeur.objets, availableObjectsIds).length > 0;
        })

        this.uniqActeurs = {};
        _.each(availableActeurs, function (objActeur){
            self.uniqActeurs[objActeur.id] = objActeur;
        })

        this.render();
    },

    render:function(){
        this.utils.fillTranslations();
    	this.headerView.render();
        this.footerView.render();
        this.parcoursSidebarView.render();
        this.parcoursTimelineView.render();
        this.parcoursTutoView.render();
        this.parcoursMapView.render();

        this.bindEvents();

        this.update();
    },

    bindEvents:function(){
    	var self = this;

        $("#SaveCopyBt").on("click", function () {
            var copyText = document.getElementById("SaveInput");
            copyText.select();
            copyText.setSelectionRange(0, 99999);


            if (!navigator.clipboard) {
                App.utils.fallbackCopyTextToClipboard(copyText.value);
                return;
            } else {
                 navigator.clipboard.writeText(copyText.value).then(function() {
                   self.$SaveIndic.addClass("displayed");
                   setTimeout(function () {
                        self.$SaveIndic.removeClass("displayed");
                    }, 2000)
                  }, function(err) {
                    console.error('Async: Could not copy text: ', err);
                  });
            }
            
            
        });

        $(".awaiting_bt").on("click", function (){
            self.$ParcoursAwaitingLayer.removeClass("displayed");
            self.parcoursMapView.update();
        })
    },

    displayAwaitingUpdate: function () {
        this.$ParcoursAwaitingLayer.addClass("displayed");
    },

    displayCancelTutoLayer: function () {
        this.$CancelTutoLayer.addClass("displayed");
    },

    update:function(fromWhere){
        var self = this;

        if(fromWhere == "sidebar") {
            this.parcoursTimelineView.reinitSliderParams();
            this.$ParcoursTitle.html("")
                                .removeClass("displayed");
        }

        this.availableLocations = {
            "creation":[],
            "decouverte":[],
            "conservation":[],
        }

        if(self.selectedItems.types.length == 0) self.selectedItems.types = ["tous"];
        if(self.selectedItems.matieres.length == 0) self.selectedItems.matieres = ["toutes"];
        if(self.selectedItems.ventes.length == 0) self.selectedItems.ventes = ["toutes"];
        if(self.selectedItems.acteurs.length == 0) self.selectedItems.acteurs = ["tous"];

        this.filteredObjetsBeforeTimeline = [];

        console.log("selectedItems", self.selectedItems);

        this.filteredObjets = _.filter(this.objetsWithLocation, function (obj){

            obj.filtering = {
                type: false,
                matiere: false,
                location: false,
                vente: false,
                acteur: false,
                locationCreation: false,
                locationDecouverte: false,
                locationConservation: false
            };

            var isFiltered = false;

            //type & matiere
            var isFilteredType = false;
            if(self.selectedItems.types.length == 1 && self.selectedItems.types[0] == "tous") {
                isFilteredType = true;
            } else {
                if(self.selectedItems.types.indexOf(obj.categorie_picto) > -1){
                    isFilteredType = true;
                }
            }

            obj.filtering.type = isFilteredType;

            var isFilteredMatiere = false;
            if(self.selectedItems.matieres.length == 1 && self.selectedItems.matieres[0] == "toutes") {
                isFilteredMatiere = true;
            } else {
                if(self.selectedItems.matieres.indexOf(obj.matiere_filtre) > -1){
                    isFilteredMatiere = true;
                }
            }

            obj.filtering.matiere = isFilteredMatiere;

            //ventes
            var isFilteredVente = false;
            if(self.selectedItems.ventes.length == 1 && self.selectedItems.ventes[0] == "toutes"){
                isFilteredVente = true;
            } else {
                _.each(self.selectedItems.ventes, function (venteNom){
                    var findObjet = _.find(self.uniqVentes[venteNom], function (o){
                        return o.id_objet == obj.id_objet;
                    })
                    if(findObjet != undefined) isFilteredVente = true;
                })
            }

            obj.filtering.vente = isFilteredVente;

            //acteurs
            var isFilteredActeur = false;
            if(self.selectedItems.acteurs.length == 1 && self.selectedItems.acteurs[0] == "tous"){
                isFilteredActeur = true;
            } else {
                _.each(self.selectedItems.acteurs, function (acteurNom){
                    var acteurObj = _.find(self.uniqActeurs, function (o){
                        return o.acteur_nom == acteurNom;
                    })

                    if(acteurObj.objets.indexOf(obj.id_objet) > -1) {
                        isFilteredActeur = true;
                    }
                })
            }
            obj.filtering.acteur = isFilteredActeur;

            //location
            var isFilteredLocation = false;
            if(self.allLocationsMode) {
                if(self.selectedItems.locations["toutes"].length == 0) {
                    isFilteredLocation = true;
                } else {
                    if(self.selectedItems.locations["toutes"].indexOf(obj.conservation_nom) > -1 || self.selectedItems.locations["toutes"].indexOf(obj.conservation_pays) > -1|| self.selectedItems.locations["toutes"].indexOf(obj.conservation_ville) > -1 || self.selectedItems.locations["toutes"].indexOf(obj.lieu_decouverte_nom) > -1 || self.selectedItems.locations["toutes"].indexOf(obj.lieu_decouverte_pays) > -1 || self.selectedItems.locations["toutes"].indexOf(obj.lieu_creation_nom) > -1 || self.selectedItems.locations["toutes"].indexOf(obj.lieu_creation_pays) > -1) {
                        isFilteredLocation = true;
                    }
                }
            } else {
                var succeedConservation = false;
                var succeedDecouverte = false;
                var succeedCreation = false;

                if(self.selectedItems.locations["creation"].length == 0) {
                    succeedCreation = true;
                }
                else {
                    if( (self.selectedItems.locations["creation"].indexOf(obj.lieu_creation_nom) > -1 || self.selectedItems.locations["creation"].indexOf(obj.lieu_creation_pays) > -1) ){
                        succeedCreation = true;
                    }
                }

                if(self.selectedItems.locations["decouverte"].length == 0) {
                    succeedDecouverte = true;
                }
                else {
                    if( (self.selectedItems.locations["decouverte"].indexOf(obj.lieu_decouverte_nom) > -1 || self.selectedItems.locations["decouverte"].indexOf(obj.lieu_decouverte_pays) > -1) ){
                        succeedDecouverte = true;
                    }
                }

                if(self.selectedItems.locations["conservation"].length == 0) {
                    succeedConservation = true;
                }
                else {
                    if( (self.selectedItems.locations["conservation"].indexOf(obj.conservation_nom) > -1 || self.selectedItems.locations["conservation"].indexOf(obj.conservation_pays) > -1 || self.selectedItems.locations["conservation"].indexOf(obj.conservation_ville) > -1) ){
                        succeedConservation = true;
                    }
                }

                if(succeedConservation && succeedDecouverte && succeedCreation) {
                    isFilteredLocation = true;
                }

                obj.filtering.locationCreation = succeedCreation;
                obj.filtering.locationDecouverte = succeedDecouverte;
                obj.filtering.locationConservation = succeedConservation;
            }
            obj.filtering.location = isFilteredLocation;

            //Timeline
            var isFilteredTimeline = false;
            var isFilteredTimelineCreation = false;
            var isFilteredTimelineDecouverte = false;
            var isFilteredTimelineVente = false;

            if(isFilteredType && isFilteredMatiere && isFilteredVente && isFilteredActeur && isFilteredLocation) {
                self.filteredObjetsBeforeTimeline.push(obj);
                //timeline creation
                if(App.parcoursTimelineView.sliderParams["creation"].timestampValues[0] == undefined || App.parcoursTimelineView.sliderParams["creation"].timestampValues[0] == null || (App.parcoursTimelineView.sliderParams["creation"].values[0] == App.parcoursTimelineView.sliderParams["creation"].min && App.parcoursTimelineView.sliderParams["creation"].values[1] == App.parcoursTimelineView.sliderParams["creation"].max)) {
                    isFilteredTimelineCreation = true;
                } else {
                    if(obj.date_creation_debut_ts == null) isFilteredTimelineCreation = false;
                     else {
                        if(obj.date_creation_debut_ts <= App.parcoursTimelineView.sliderParams["creation"].timestampValues[1] && obj.date_creation_debut_ts >= App.parcoursTimelineView.sliderParams["creation"].timestampValues[0]) {
                            isFilteredTimelineCreation = true;
                        }
                    }
                }

                //timeline decouverte
                if(isFilteredTimelineCreation) {
                    if(App.parcoursTimelineView.sliderParams["decouverte"].timestampValues[0] == undefined || App.parcoursTimelineView.sliderParams["decouverte"].timestampValues[0] == null || (App.parcoursTimelineView.sliderParams["decouverte"].values[0] == App.parcoursTimelineView.sliderParams["decouverte"].min && App.parcoursTimelineView.sliderParams["decouverte"].values[1] == App.parcoursTimelineView.sliderParams["decouverte"].max)) {
                        isFilteredTimelineDecouverte = true;
                    } else {
                        if(obj.date_decouverte_debut_ts == null) isFilteredTimelineDecouverte = false;
                         else {
                            if(obj.date_decouverte_debut_ts <= App.parcoursTimelineView.sliderParams["decouverte"].timestampValues[1] && obj.date_decouverte_debut_ts >= App.parcoursTimelineView.sliderParams["decouverte"].timestampValues[0]) {
                                isFilteredTimelineDecouverte = true;
                            }
                        }
                    }
                }

                //timeline vente
                if(isFilteredTimelineCreation && isFilteredTimelineDecouverte) {
                    if(App.parcoursTimelineView.sliderParams["vente"].timestampValues[0] == undefined || App.parcoursTimelineView.sliderParams["vente"].timestampValues[0] == null || (App.parcoursTimelineView.sliderParams["vente"].values[0] == App.parcoursTimelineView.sliderParams["vente"].min && App.parcoursTimelineView.sliderParams["vente"].values[1] == App.parcoursTimelineView.sliderParams["vente"].max)) {
                        isFilteredTimelineVente = true;
                    } else {
                        if(obj.sortedVentes.length > 0) {
                            var venteBeginTs = obj.sortedVentes[0].vente_date_ts;
                            var venteEndTs = obj.sortedVentes[obj.sortedVentes.length - 1].vente_date_ts;

                            if(venteBeginTs <= App.parcoursTimelineView.sliderParams["vente"].timestampValues[1] && venteEndTs >= App.parcoursTimelineView.sliderParams["vente"].timestampValues[0]) {
                                isFilteredTimelineVente = true;
                            }
                        }
                    }
                }
            }

            if(isFilteredTimelineCreation && isFilteredTimelineDecouverte && isFilteredTimelineVente) {
                isFilteredTimeline = true;
            }

            
            if(self.allLocationsMode) {
                if(isFilteredType && isFilteredMatiere && isFilteredVente && isFilteredActeur) {
                    if(obj.conservation_nom !== "" && obj.conservation_nom !== undefined){
                        var suffixDisplay = "";
                        if(obj.conservation_ville !== "" && obj.conservation_ville !== undefined && obj.conservation_ville != "Information manquante"){
                            suffixDisplay = " (" + obj.conservation_ville + ")";
                        } 
                        else if(obj.conservation_pays !== "" && obj.conservation_pays !== undefined && obj.conservation_pays != "Information manquante"){
                            suffixDisplay = " (" + obj.conservation_pays + ")";
                        } 
                        //self.availableLocations.conservation.push({ value:obj.conservation_nom, label:obj.conservation_nom + suffixDisplay, type:"country" });

                        if(obj.conservation_pays != "") self.availableLocations.conservation.push({value: obj.conservation_pays, label: obj.conservation_pays, type: "country"});
                        if(obj.conservation_nom != "" && obj.conservation_nom != "Localisation inconnue") self.availableLocations.conservation.push({value: obj.conservation_nom, label: obj.conservation_nom, type: "location"});
                        if(obj.conservation_ville != "") self.availableLocations.conservation.push({value: obj.conservation_ville, label: obj.conservation_ville, type: "location"});
                    }

                    if(obj.lieu_decouverte_nom !== "" && obj.lieu_decouverte_nom !== undefined && obj.lieu_decouverte_nom != "Localisation inconnue") self.availableLocations.decouverte.push({ value:obj.lieu_decouverte_nom, label:obj.lieu_decouverte_nom, type:"location" });
                    if(obj.lieu_decouverte_pays !== "" && obj.lieu_decouverte_pays !== undefined) self.availableLocations.decouverte.push({ value:obj.lieu_decouverte_pays, label:obj.lieu_decouverte_pays, type:"country" });

                    if(obj.lieu_creation_nom !== "" && obj.lieu_creation_nom !== undefined && obj.lieu_creation_type != "pays" && obj.lieu_creation_nom != "Localisation inconnue") self.availableLocations.creation.push({ value:obj.lieu_creation_nom, label:obj.lieu_creation_nom, type:"location" });
                    if(obj.lieu_creation_pays !== "" && obj.lieu_creation_pays !== undefined) self.availableLocations.creation.push({ value:obj.lieu_creation_pays, label:obj.lieu_creation_pays, type:"country" });
                }
            } else {
                if(isFilteredType && isFilteredMatiere && isFilteredVente && isFilteredActeur && obj.filtering.locationDecouverte && obj.filtering.locationConservation){
                    if(obj.lieu_creation_nom !== "" && obj.lieu_creation_nom !== undefined && obj.lieu_creation_type != "pays" && obj.lieu_creation_nom != "Localisation inconnue") self.availableLocations.creation.push({ value:obj.lieu_creation_nom, label:obj.lieu_creation_nom, type:"location" });
                    if(obj.lieu_creation_pays !== "" && obj.lieu_creation_pays !== undefined) self.availableLocations.creation.push({ value:obj.lieu_creation_pays, label:obj.lieu_creation_pays, type:"country" });
                }

                if(isFilteredType && isFilteredMatiere && isFilteredVente && isFilteredActeur && obj.filtering.locationCreation && obj.filtering.locationConservation){
                    if(obj.lieu_decouverte_nom !== "" && obj.lieu_decouverte_nom !== undefined && obj.lieu_decouverte_nom != "Localisation inconnue") self.availableLocations.decouverte.push({ value:obj.lieu_decouverte_nom, label:obj.lieu_decouverte_nom, type:"location" });
                    if(obj.lieu_decouverte_pays !== "" && obj.lieu_decouverte_pays !== undefined) self.availableLocations.decouverte.push({ value:obj.lieu_decouverte_pays, label:obj.lieu_decouverte_pays, type:"country" });
                }

                if(isFilteredType && isFilteredMatiere && isFilteredVente && isFilteredActeur && obj.filtering.locationCreation && obj.filtering.locationDecouverte){
                    if(obj.conservation_nom !== "" && obj.conservation_nom !== undefined){
                        var suffixDisplay = "";
                        if(obj.conservation_ville !== "" && obj.conservation_ville !== undefined && obj.conservation_ville != "Information manquante"){
                            suffixDisplay = " (" + obj.conservation_ville + ")";
                        } 
                        else if(obj.conservation_pays !== "" && obj.conservation_pays !== undefined && obj.conservation_pays != "Information manquante"){
                            suffixDisplay = " (" + obj.conservation_pays + ")";
                        } 
                        //self.availableLocations.conservation.push({ value:obj.conservation_nom, label:obj.conservation_nom + suffixDisplay, type:"country" });

                        if(obj.conservation_pays != "") self.availableLocations.conservation.push({value: obj.conservation_pays, label: obj.conservation_pays, type: "country"});
                        if(obj.conservation_nom != "" && obj.conservation_nom != "Localisation inconnue") self.availableLocations.conservation.push({value: obj.conservation_nom, label: obj.conservation_nom, type: "location"});
                        if(obj.conservation_ville != "") self.availableLocations.conservation.push({value: obj.conservation_ville, label: obj.conservation_ville, type: "location"});
                    }
                }
            }

            if(isFilteredType && isFilteredMatiere && isFilteredVente && isFilteredActeur && isFilteredTimeline && isFilteredLocation) isFiltered = true;
            return isFiltered;
        });

        console.log("filteredObjets", this.filteredObjets);
        
        this.$parcoursNb.html(this.filteredObjets.length);
        if(this.filteredObjets.length > 1) this.$parcoursFiltres.html("filtrés");
        else this.$parcoursFiltres.html("filtré");
        
        this.filteredParcours = [];

        var toFixedApprox = 0;

        _.each(this.filteredObjets, function (obj){
            if(obj.parcoursCreaDec) self.filteredParcours.push({
                "id_objet": obj.id_objet,
                "type": "crea-dec",
                "real_latitude_source": parseFloat(obj.lieu_creation_lat),
                "real_longitude_source": parseFloat(obj.lieu_creation_long),
                "real_latitude_target": parseFloat(obj.lieu_decouverte_lat),
                "real_longitude_target": parseFloat(obj.lieu_decouverte_long),
                "latitude_source": parseFloat(parseFloat(obj.lieu_creation_lat).toFixed(toFixedApprox)),
                "longitude_source": parseFloat(parseFloat(obj.lieu_creation_long).toFixed(toFixedApprox)),
                "latitude_target": parseFloat(parseFloat(obj.lieu_decouverte_lat).toFixed(toFixedApprox)),
                "longitude_target": parseFloat(parseFloat(obj.lieu_decouverte_long).toFixed(toFixedApprox)),
                "color_source": [242,175,73],
                "color_target": [255, 107, 15]
            })

            if(obj.parcoursCreaCons) self.filteredParcours.push({
                "id_objet": obj.id_objet,
                "type": "crea-cons",
                "real_latitude_source": parseFloat(obj.lieu_creation_lat),
                "real_longitude_source": parseFloat(obj.lieu_creation_long),
                "real_latitude_target": parseFloat(obj.lieu_conservation_lat),
                "real_longitude_target": parseFloat(obj.lieu_conservation_long),
                "latitude_source": parseFloat(parseFloat(obj.lieu_creation_lat).toFixed(toFixedApprox)),
                "longitude_source": parseFloat(parseFloat(obj.lieu_creation_long).toFixed(toFixedApprox)),
                "latitude_target": parseFloat(parseFloat(obj.lieu_conservation_lat).toFixed(toFixedApprox)),
                "longitude_target": parseFloat(parseFloat(obj.lieu_conservation_long).toFixed(toFixedApprox)),
                "color_source": [242,175,73],
                "color_target": [23,86,118]
            })

            if(obj.parcoursDecCons) self.filteredParcours.push({
                "id_objet": obj.id_objet,
                "type": "dec-cons",
                "real_latitude_source": parseFloat(obj.lieu_decouverte_lat),
                "real_longitude_source": parseFloat(obj.lieu_decouverte_long),
                "real_latitude_target": parseFloat(obj.lieu_conservation_lat),
                "real_longitude_target": parseFloat(obj.lieu_conservation_long),
                "latitude_source": parseFloat(parseFloat(obj.lieu_decouverte_lat).toFixed(toFixedApprox)),
                "longitude_source": parseFloat(parseFloat(obj.lieu_decouverte_long).toFixed(toFixedApprox)),
                "latitude_target": parseFloat(parseFloat(obj.lieu_conservation_lat).toFixed(toFixedApprox)),
                "longitude_target": parseFloat(parseFloat(obj.lieu_conservation_long).toFixed(toFixedApprox)),
                "color_source": [255, 107, 15],
                "color_target": [23,86,118]
            })

            if(obj.parcoursDecVente) self.filteredParcours.push({
                "id_objet": obj.id_objet,
                "type": "dec-vente",
                "real_latitude_source": parseFloat(obj.lieu_decouverte_lat),
                "real_longitude_source": parseFloat(obj.lieu_decouverte_long),
                "real_latitude_target": parseFloat(obj.sortedVentes[0].vente_lat),
                "real_longitude_target": parseFloat(obj.sortedVentes[0].vente_long),
                "latitude_source": parseFloat(parseFloat(obj.lieu_decouverte_lat).toFixed(toFixedApprox)),
                "longitude_source": parseFloat(parseFloat(obj.lieu_decouverte_long).toFixed(toFixedApprox)),
                "latitude_target": parseFloat(parseFloat(obj.sortedVentes[0].vente_lat).toFixed(toFixedApprox)),
                "longitude_target": parseFloat(parseFloat(obj.sortedVentes[0].vente_long).toFixed(toFixedApprox)),
                "color_source": [255, 107, 15],
                "color_target": [219, 66, 114]
            })

            if(obj.parcoursCreaVente) self.filteredParcours.push({
                "id_objet": obj.id_objet,
                "type": "crea-vente",
                "real_latitude_source": parseFloat(obj.lieu_creation_lat),
                "real_longitude_source": parseFloat(obj.lieu_creation_long),
                "real_latitude_target": parseFloat(obj.sortedVentes[0].vente_lat),
                "real_longitude_target": parseFloat(obj.sortedVentes[0].vente_long),
                "latitude_source": parseFloat(parseFloat(obj.lieu_creation_lat).toFixed(toFixedApprox)),
                "longitude_source": parseFloat(parseFloat(obj.lieu_creation_long).toFixed(toFixedApprox)),
                "latitude_target": parseFloat(parseFloat(obj.sortedVentes[0].vente_lat).toFixed(toFixedApprox)),
                "longitude_target": parseFloat(parseFloat(obj.sortedVentes[0].vente_long).toFixed(toFixedApprox)),
                "color_source": [242, 175, 73],
                "color_target": [219, 66, 114]
            })

            if(obj.parcoursVenteVente) self.filteredParcours.push({
                "id_objet": obj.id_objet,
                "type": "vente-vente",
                "real_latitude_source": parseFloat(obj.sortedVentes[0].vente_lat),
                "real_longitude_source": parseFloat(obj.sortedVentes[0].vente_long),
                "real_latitude_target": parseFloat(obj.sortedVentes[1].vente_lat),
                "real_longitude_target": parseFloat(obj.sortedVentes[1].vente_long),
                "latitude_source": parseFloat(parseFloat(obj.sortedVentes[0].vente_lat).toFixed(toFixedApprox)),
                "longitude_source": parseFloat(parseFloat(obj.sortedVentes[0].vente_long).toFixed(toFixedApprox)),
                "latitude_target": parseFloat(parseFloat(obj.sortedVentes[1].vente_lat).toFixed(toFixedApprox)),
                "longitude_target": parseFloat(parseFloat(obj.sortedVentes[1].vente_long).toFixed(toFixedApprox)),
                "color_source": [219, 66, 114],
                "color_target": [219, 66, 114]
            })

            if(obj.parcoursVenteCons) {
                var venteLat = obj.sortedVentes[0].vente_lat;
                var venteLong = obj.sortedVentes[0].vente_long;
                if(obj.sortedVentes.length > 1) {
                    venteLat = obj.sortedVentes[1].vente_lat;
                    venteLong = obj.sortedVentes[1].vente_long;
                }

                self.filteredParcours.push({
                    "id_objet": obj.id_objet,
                    "type": "dec-vente",
                    "real_latitude_source": parseFloat(venteLat),
                    "real_longitude_source": parseFloat(venteLong),
                    "real_latitude_target": parseFloat(obj.lieu_conservation_lat),
                    "real_longitude_target": parseFloat(obj.lieu_conservation_long),
                    "latitude_source": parseFloat(parseFloat(venteLat).toFixed(toFixedApprox)),
                    "longitude_source": parseFloat(parseFloat(venteLong).toFixed(toFixedApprox)),
                    "latitude_target": parseFloat(parseFloat(obj.lieu_conservation_lat).toFixed(toFixedApprox)),
                    "longitude_target": parseFloat(parseFloat(obj.lieu_conservation_long).toFixed(toFixedApprox)),
                    "color_source": [219, 66, 114],
                    "color_target": [23, 86, 118]
                })
            }
                
        });

        _.each(this.filteredParcours, function (o){
            o.id_real_parcours = o.real_latitude_source + "-" + o.real_longitude_source + "[to]" + o.real_latitude_target + "-" + o.real_longitude_target + "[" + o.type + "]";;
            o.id_parcours = o.latitude_source + "-" + o.longitude_source + "[to]" + o.latitude_target + "-" + o.longitude_target + "[" + o.type + "]";
        })

        var tmpGroupedRealParcours =  _.groupBy(this.filteredParcours, function (o){
            return o.id_real_parcours;
        })

        this.groupedRealParcours = {};
        _.each(tmpGroupedRealParcours, function (arr, groupId){
            self.groupedRealParcours[groupId] = {
                id:groupId,
                id_parcours: arr[0].id_parcours,
                parcours: arr
            }
        })

        var groupedSimilarParcours = _.groupBy(self.groupedRealParcours, function (o){
            return o.id_parcours;
        })

        _.each(this.groupedRealParcours, function (o){
            var inc = 0;
            _.each(groupedSimilarParcours[o.id_parcours], function (simObj, index){
                if(simObj.id == o.id) inc = index;
            })
            o.height = 1 + (inc/3);
        })

        console.log("groupedRealParcours", this.groupedRealParcours)
        
        this.parcoursTimelineView.update();
        this.parcoursSidebarView.update();
        this.parcoursTutoView.update();

        this.$CancelTutoLayer.removeClass("displayed");
        if(!this.isFirstUpdate) {
            if(!this.isReloadingMap) this.displayAwaitingUpdate();
            else this.parcoursMapView.update();
        } else {
            if(this.cancelTuto) {
                this.displayCancelTutoLayer();
                this.cancelTuto = false;
                this.isFirstUpdate = false;
            } else {
                this.isFirstUpdate = false;
                if(this.parcoursTutoView.tutoSeen != null) this.parcoursMapView.update();
            }
        }

        this.forceFeatured = false;

        setTimeout(function (){
            self.parcoursMapView.initialLoading = false;
        }, 1000);
    },

    generateParcoursSaveURL: function () {
        var genereatedId = App.utils.makeid(40);
        this.parcoursSaveURL = location.href.split("?")[0] + "?id=" + genereatedId;
        this.$SaveInput.val(this.parcoursSaveURL);

        App.utils.saveParcoursURL(genereatedId, this.selectedItems, function (){
            console.log("done");
        });
    },

    reloadMap: function () {
        var self = this;
        this.parcoursMapView.reload();
        this.isReloadingMap = true;
    }
};

module.exports = App;
window.App = App;