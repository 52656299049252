var Utils = Backbone.View.extend({

    initialize:function(){
        var self = this;

        this.translations = {};
        this.creditsphoto = [];
        this.objets = {};
        this.ventes = {};
        this.acteurs = {};
        this.rootAPI = "https://inha.wedodata.fr/api/";

        this.DBReferentiel = [];

        this.parcoursFeaturedItems = [
            /*{ id: "nola", title: "Où sont les oeuvres découvertes à Nola ?", parcoursId: "8xD1FV1NTBG67pS37wsGVJCfxB7eOvQzW0gZnBFQ", img:"UK_136343.jpg", text:"Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte "},
            { id: "vase-athenes", title: "Que deviennent les vases créés à Athènes ?", parcoursId: "lt26Guu6JTQbAffkG6XTBUfr4zsmopaDaCOZEQBJ", img: "UK_136624.jpg", text:"Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte " },
            { id: "bronze-canino", title: "Que deviennent les bronzes découverts à Canino ?", parcoursId: "KI6p0fduJJP6UlPaJknJFFrMllZNKhWuyTDuyYuS", img: "UK_140444.jpg", text:"Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte "},
            { id: "athenes", title: "Que deviennent les vases conservés à Londres ?", parcoursId: "lWaJbxGP22i4Urm725jDPmIJ9kY41n4nHpy2RMsq", img: "UK_140794.jpg", text:"Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte " },
            { id: "nola-2", title: "Où sont les oeuvres découvertes à Nola ?", parcoursId: "8xD1FV1NTBG67pS37wsGVJCfxB7eOvQzW0gZnBFQ", img: "UK_136343.jpg", text:"Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte "},
            { id: "vase-athenes-2", title: "Que deviennent les vases créés à Athènes ?", parcoursId: "lt26Guu6JTQbAffkG6XTBUfr4zsmopaDaCOZEQBJ", img: "UK_136624.jpg", text:"Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte " },
            { id: "bronze-canino-2", title: "Que deviennent les bronzes découverts à Canino ?", parcoursId: "KI6p0fduJJP6UlPaJknJFFrMllZNKhWuyTDuyYuS", img: "UK_140444.jpg", text:"Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte "},
            { id: "athenes-2", title: "Que deviennent les vases conservés à Londres ?", parcoursId: "lWaJbxGP22i4Urm725jDPmIJ9kY41n4nHpy2RMsq", img: "UK_140794.jpg", text:"Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte " },
            { id: "nola-3", title: "Où sont les oeuvres découvertes à Nola ?", parcoursId: "8xD1FV1NTBG67pS37wsGVJCfxB7eOvQzW0gZnBFQ", img: "UK_136343.jpg", text:"Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte "},
            { id: "vase-athenes-3", title: "Que deviennent les vases créés à Athènes ?", parcoursId: "lt26Guu6JTQbAffkG6XTBUfr4zsmopaDaCOZEQBJ", img: "UK_136624.jpg", text:"Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un peintre anonyme assembla ensemble des morceaux de texte " }
        */];
    },

    gatherData:function(callback){
        var self = this;
        this.gatherTranslations(function(){
            self.gatherAntiquitiesData(function(){
                self.gatherCreditsPhoto(function(){
                    return callback();
                });
            });
        });
    },

    gatherParcoursSummaryData: function (callback) {
        var self = this;
        this.parcoursFeaturedItems = [];

        d3.csv("data/parcoursSummary.csv")
            .then(function(arrayData){
                _.each(arrayData, function (data){
                    self.parcoursFeaturedItems.push({
                        title: data.title,
                        parcoursId: data.parcoursId,
                        media: data.media,
                        text: data.text
                    })
                })
               
                return callback();
            })
            .catch(function(error, rows){
                console.log(error, rows);
                return callback();
            })

        /* 
        d3.csv("data/parcoursSummary.csv", function(data){
            console.log("parcoursSummary", data);
            self.parcoursFeaturedItems.push({
                title: data.title,
                parcoursId: data.parcoursId,
                img: data.img,
                text: data.text
            })
        }, function(error, rows) {
            console.log(error, rows);
            return callback();
        });
        */
    },

    gatherTranslations:function(callback){
        var self = this;
        d3.csv("data/translations.csv")
            .then(function (arrayData){
                _.each(arrayData, function (data){
                    self.translations[data.key] = {
                        en:data.en,
                        fr:data.fr,
                    };
                })
                
                return callback();
            })
            .catch(function(error){
                console.log(error, rows);
                return callback();
            })
        /*
        d3.csv("data/translations.csv", function(data){
            self.translations[data.key] = {
                en:data.en,
                fr:data.fr,
            };
        }, function(error, rows) {
            console.log(error, rows);
            return callback();
        });
        */
    },

    gatherAntiquitiesData:function(callback){
        var self = this;

        App.csvVentes = "";

        d3.csv("data/INHA_objets.csv")
            .then(function(arrayData){
                _.each(arrayData, function (data){
                    if(data.id_objet != ""){
                        data.conservation_nom = data.conservation_nom.replace(/'/g,'’');
                        self.objets[data.id_objet] = self.processObjetData(data);
                    }
                    if(data.categorie_picto == ""){
                        data.categorie_picto = "inconnu";
                    }
                })
                

                d3.csv("data/INHA_acteurs.csv")
                .then(function(arrayData2){
                    _.each(arrayData2, function (data){
                        if(data.uk_acteur != ""){
                            data.acteur_nom = data.acteur_nom.replace(/'/g,'’');
                            data.image_exists = (data.url_medias == "") ? 0 : 1;
                            self.acteurs[data.uk_acteur] = self.processActeurData(data);
                        }
                    });
                    d3.csv("data/INHA_ventes.csv")
                        .then(function (arrayData3){
                            _.each(arrayData3, function (data){
                                if(data.type_vente == "vente de lot" && data.id_objet != ""){
                                    App.csvVentes += data.id_objet  + "," + data.vente_nom + "," + data.id_appvente + "," + data.type_vente + "," + data.id_vente + "," + data.vente_date + "," + data.vente_ville + "," + data.vente_pays + "," + data.vente_adresse_1 + "," + data.vente_long + "," + data.vente_lat + "," + data.vente_vendeur + "," + data.uk_acteur_vente_vendeur + "," + data.vente_acheteur + "," + data.uk_acteur_vente_acheteur + "," + data.ventes_prix_francs + "," + data.url_vente + "\n";

                                    var processedData = self.processVenteData(data);
                                    self.ventes[data.id_appvente] = processedData;

                                    if(self.objets[data.id_objet] !== undefined){
                                        self.objets[data.id_objet].ventes[data.id_appvente] = processedData;
                                    }
                                }
                            });

                            self.sortVentesInObjets();
                            self.populateActeursFromVentes();
                            return callback();
                        })
                })
            })
        /*
        d3.csv("data/new-2022/INHA_objets_new.csv", function(data){
                data.conservation_nom = data.conservation_nom.replace(/'/g,'’');
                if(data.id_objet != "") self.objets[data.id_objet] = self.processObjetData(data);
        }, function(error, rows) {
            d3.csv("data/new-2022/INHA_acteurs_new.csv", function(data){
                if(data.uk_acteur != ""){
                    data.acteur_nom = data.acteur_nom.replace(/'/g,'’');
                    self.acteurs[data.uk_acteur] = self.processActeurData(data);
                }
            }, function(error, rows){
                d3.csv("data/new-2022/INHA_ventes_new.csv", function(data){
                    if(data.type_vente == "vente de lot" && data.id_objet != ""){
                        var processedData = self.processVenteData(data);
                        self.ventes[data.id_appvente] = processedData;
                        if(self.objets[data.id_objet] !== undefined){
                            self.objets[data.id_objet].ventes[data.id_appvente] = processedData;
                        }
                    }
                }, function(error, rows){
                    console.log(error, rows);
                    self.sortVentesInObjets();
                    self.populateActeursFromVentes();
                    return callback();
                });
            });
        });
        */
    },

    processObjetData:function(data){
        var self = this;
        data.type_objet = data.type_objet.replace(/'/g, '’');
        data.categorie_picto = data.categorie_picto.replace(/'/g, '’');
        data.matiere_filtre = data.matiere_filtre.replace(/'/g, '’');

        if(data.date_creation_debut !== ""){
            data.date_creation_debut_ts = this.getTimestampFromYear(data.date_creation_debut);
        }else{
            data.date_creation_debut = this.translations["information-manquante"][App.selectedLang];
            data.date_creation_debut_ts = null;
        }

        if(data.date_decouverte_debut !== ""){
            data.date_decouverte_debut_ts = this.getTimestampFromYear(data.date_decouverte_debut);
        }else{
            data.date_decouverte_debut = this.translations["information-manquante"][App.selectedLang];
            data.date_decouverte_debut_ts = null;
        }

        if(data.matiere_filtre == ""){
            data.matiere_filtre = null;
        }

        /* if(data.lieu_decouverte_type == "ville"){
            data.lieu_decouverte_type = "certain";
        } else{
            data.lieu_decouverte_type = "incertain";
        } */

        /*if(data.conservation_nom !== ""){
            var slugNom = _.str.slugify(data.conservation_nom);
            if(this.acteurs[slugNom] === undefined){
                this.acteurs[slugNom] = {
                    id:slugNom,
                    nom:data.conservation_nom,
                    type:"musee",
                    ventes:[],
                    transactions:[],
                    objets:[],
                    totalVentes:0,
                    totalAchats:0
                }
            }
            if(_.indexOf(this.acteurs[slugNom].objets, data.id_objet) == -1) this.acteurs[slugNom].objets.push(data.id_objet);
        }*/

        var informationManquante = ["lieu_creation_nom","dim_cm","date_creation_string","auteur_objet","lieu_decouverte_nom","lieu_decouverte_pays","date_decouverte_string","conservation_nom","conservation_ville","conservation_pays","conservation_date_entree"];

        _.each(informationManquante,function(d,i){

            if(data[d]== ""){
                data[d] = self.translations["information-manquante"][App.selectedLang];
            }    

        });

        data.ventes = {};
        return data;
    },

    processActeurData:function(data){
        data.id = data.uk_acteur;
        return data;
    },

    processVenteData:function(data){
        data.vente_date_ts = this.getTimestampFromLiteralDateFR(data.vente_date);
        if(data.ventes_prix_francs !== ""){
            data.ventes_prix_value = parseFloat(data.ventes_prix_francs.replace(/\s/g, '').replace(",", "."));
        }else{
            data.ventes_prix_value = null;
        }

        return data;
    },

    sortVentesInObjets:function(){
        _.each(App.utils.objets, function(o){
            o.sortedVentes = _.sortBy(o.ventes, function(v){
                return v.vente_date_ts;
            });
        });
    },

    populateActeursFromVentes:function(){
        var self = this;
        _.each(this.ventes, function(data){
            if(data.uk_acteur_vente_vendeur !== ""){
                data.type = "vente";
                var slugVendeur = data.uk_acteur_vente_vendeur;
                var slugNetworkAcheteur = data.uk_acteur_vente_acheteur;
                if(self.acteurs[slugVendeur] === undefined){
                    console.log("NOT FOUND ACTEUR vendeur", data);
                } else {
                    if(self.acteurs[slugVendeur].ventes == undefined){
                        self.acteurs[slugVendeur].ventes = [];
                        self.acteurs[slugVendeur].type = "";
                        self.acteurs[slugVendeur].transactions = [];
                        self.acteurs[slugVendeur].objets = [];
                        self.acteurs[slugVendeur].network = {};
                        self.acteurs[slugVendeur].totalVentes = 0;
                        self.acteurs[slugVendeur].totalAchats = 0;
                    }

                    self.acteurs[slugVendeur].ventes.push(data.id_appvente);
                    self.acteurs[slugVendeur].transactions.push(data);
                    if(_.indexOf(self.acteurs[slugVendeur].objets, data.id_objet) == -1) self.acteurs[slugVendeur].objets.push(data.id_objet);
                    self.acteurs[slugVendeur].totalVentes += data.ventes_prix_value;
                    if(self.acteurs[slugVendeur].type == "acheteur") self.acteurs[slugVendeur].type = "acheteur-vendeur";
                    else if(self.acteurs[slugVendeur].type == "") self.acteurs[slugVendeur].type = "vendeur";

                    if(slugNetworkAcheteur !== ""){
                        if(self.acteurs[slugVendeur].network[slugNetworkAcheteur] === undefined){
                            self.acteurs[slugVendeur].network[slugNetworkAcheteur] = {
                                id:slugNetworkAcheteur,
                                type:"group_acheteur",
                                nbObjets:0
                            }
                        }else{
                            if(self.acteurs[slugVendeur].network[slugNetworkAcheteur].type == "group_vendeur") self.acteurs[slugVendeur].network[slugNetworkAcheteur].type = "group_acheteur_vendeur";
                        }
                        self.acteurs[slugVendeur].network[slugNetworkAcheteur].nbObjets += 1;
                    }
                }
                
            }

            if(data.uk_acteur_vente_acheteur !== ""){
                data.type = "achat";
                var slugAcheteur = data.uk_acteur_vente_acheteur;
                var slugNetworkVendeur = data.uk_acteur_vente_vendeur;

                if(self.acteurs[slugAcheteur] === undefined){
                    console.log("NOT FOUND ACTEUR acheteur", data);
                } else {
                    if(self.acteurs[slugAcheteur].ventes == undefined){
                        self.acteurs[slugAcheteur].ventes = [];
                        self.acteurs[slugAcheteur].type = "";
                        self.acteurs[slugAcheteur].transactions = [];
                        self.acteurs[slugAcheteur].objets = [];
                        self.acteurs[slugAcheteur].network = {};
                        self.acteurs[slugAcheteur].totalVentes = 0;
                        self.acteurs[slugAcheteur].totalAchats = 0;
                    }

                    self.acteurs[slugAcheteur].ventes.push(data.id_appvente);
                    self.acteurs[slugAcheteur].transactions.push(data);
                    if(_.indexOf(self.acteurs[slugAcheteur].objets, data.id_objet) == -1) self.acteurs[slugAcheteur].objets.push(data.id_objet);
                    self.acteurs[slugAcheteur].totalAchats += data.ventes_prix_value;
                    if(self.acteurs[slugAcheteur].type == "vendeur") self.acteurs[slugAcheteur].type = "acheteur-vendeur";
                    else if(self.acteurs[slugAcheteur].type == "") self.acteurs[slugAcheteur].type = "acheteur";

                    if(slugNetworkVendeur !== ""){
                        if(self.acteurs[slugAcheteur].network[slugNetworkVendeur] === undefined){
                            self.acteurs[slugAcheteur].network[slugNetworkVendeur] = {
                                id:slugNetworkVendeur,
                                type:"group_vendeur",
                                nbObjets:0
                            };
                        }else{
                            if(self.acteurs[slugAcheteur].network[slugNetworkVendeur].type == "group_acheteur") self.acteurs[slugAcheteur].network[slugNetworkVendeur].type = "group_acheteur_vendeur";
                        }
                        self.acteurs[slugAcheteur].network[slugNetworkVendeur].nbObjets += 1;
                    }
                }
            }
        });

        _.each(self.acteurs, function(a){
            self.acteurs[a.id].uniqVentes = _.keys(_.groupBy(a.transactions, function(t){ return t.vente_nom; })).length;
        })
    },

    gatherCreditsPhoto:function(callback){
        var self = this;

        d3.csv("data/credit-photo-inha4.csv")
            .then(function (arrayData){
                _.each(arrayData, function (data){
                    self.creditsphoto.push({
                        name:data.nom,
                        cotenum:data.cotenum,
                        location:data.lieu,
                        titulaire:data.titulaire
                    });
                })
                
                return callback();
            })
            .catch(function(error){
                console.log(error);
                return callback();
            })

            /*
        d3.csv("data/credit-photo-inha4.csv", function(data){
            self.creditsphoto.push({
                name:data.nom,
                cotenum:data.cotenum,
                location:data.lieu,
                titulaire:data.titulaire
            });
        }, function(error, rows) {
            console.log(error, rows);
            return callback();
        });
        */
    },

    fillTranslations:function(){
        var self = this;

        _.each($(".transblock"), function(ele){
            var $ele = $(ele);
            var dataTrans = $ele.attr("data-trans");
            $ele.html(self.translations[dataTrans][App.selectedLang]);
        });

        $(".head_langbt[data-lang='"+App.selectedLang+"']").addClass("selectedLang");
    },

    displayMenuActiveBtn:function(page){
        $(".menu_bt").removeClass("active");
        $(".menu_bt[data-page='"+page+"']").addClass("active");
    },

    /* retourne les objets stars */
    getStarObjects:function(){
        var self = this;
        var objets = self.objets;
        var stars= [];
        _.each(objets,function(obj,i){
            if(obj.classement_objet==1){
                stars.push(obj);
            }
        });
        return stars;
    },

    /* retourne un id d'objet star au hasard*/
    getRandomStarId:function(){
        var self = this;
        var stars = this.getStarObjects();
        var stars_id=[];
        _.each(stars,function(star,i){
            stars_id.push(star.id_objet)
        });
        var random_star = stars_id[Math.floor(Math.random() * stars_id.length)];
        return random_star;
    },

    getTimestampFromYear:function(literalYear){
        if(literalYear == "") return "";
        var date = new Date();
        date.setYear(parseInt(literalYear.replace(/\s/g, ''), 10));
        date.setMonth(0);
        date.setDate(1);
        return parseInt(date.getTime()/1000, 10);
    },

    /* retourn un unix timestamp à partir d'une date écrite en français littéral (ex: 2 mai 1836)*/
    getTimestampFromLiteralDateFR:function(literalDate){
        /*
        var splitDate = literalDate.split("/");
        var myMoment = moment(splitDate[2]+"-"+splitDate[1]+"-"+splitDate[0]);
        return parseInt(moment(myMoment).format("X"), 10);
        */

        var splitDate = literalDate.split("/");
        var myMoment = moment(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`, "YYYY-MM-DD");
        return parseInt(myMoment.format("X"), 10);
    },

    getMonthNbFromLiteral:function(literalMonth){
        switch(literalMonth){
            case "janvier": return "01";
            case "février": return "02";
            case "février": return "02";
            case "mars": return "03";
            case "avril": return "04";
            case "mai": return "05";
            case "juin": return "06";
            case "juillet": return "07";
            case "aout": return "08";
            case "septembre": return "09";
            case "octobre": return "10";
            case "novembre": return "11";
            case "décembre": return "12";
        }
    },

    getTwoDigitsDay:function(literalDay){
        if(literalDay == "1er") return "01";
        if(literalDay.length == 1){
            return "0"+literalDay;
        }
        return literalDay;
    },

    /* Réécrit les dates string avec les siècle en chiffre romain et des exposants après les chiffres */
    rewriteDateString:function(datestring){
        var self = this;

        tabSiecle = []

        for(var i=1;i<=21;i++){

            var rom = this.romanize(i);

            if(i == 1){
                var siecle = "I<sup>er</sup> siècle"
            }else{
                var siecle = rom+"<sup>e</sup> siècle"
            }

            tabSiecle.push(siecle);
        }

        var rewrited = this.spotGoodString(tabSiecle,datestring);
        if(typeof rewrited == "undefined") return datestring;
        var chiffre = rewrited.substring(0,1);

        if(chiffre == 1){
            var rewritedALl = rewrited.replace("1er","1<sup>er</sup>");
        }else{
            var rewritedALl = rewrited.replace(chiffre+"e",chiffre+"<sup>e</sup>");
        }

        return(rewritedALl);

    },

    spotGoodString:function(tabnewstring,datestring){

        for(var j=0;j<=tabSiecle.length;j++){
            y = j+1;
            var siecleOld = y+"e siècle";
            var sRegExInput = new RegExp(siecleOld, "g");
            var daterewrite = datestring.replace(siecleOld,tabSiecle[j]);
            if(daterewrite !== datestring){
                return daterewrite
            }
        }

    },

    romanize:function(num) {
        if (!+num) return NaN;
        var digits = String(+num).split(""),
        key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
               "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
               "","I","II","III","IV","V","VI","VII","VIII","IX"],
        roman = "",
        i = 3;
        while (i--)
            roman = (key[+digits.pop() + (i * 10)] || "") + roman;
            return Array(+digits.join("") + 1).join("M") + roman;
    },

    imageExists:function(imageURL, callback){
        
        $.get(imageURL)
            .done(function() { 
                return callback(true);
            }).fail(function() { 
                return callback(false);
            });
    },

    imageExistsValeur: function(url, imageID, placeholder, callback) {
        var img = new Image();
        img.onload = function() { callback(true, imageID, url, placeholder); };
        img.onerror = function() { callback(false, imageID, url, placeholder); };
        img.src = url;
    },

    imageExists2:function(imageexists, callback){
        if(imageexists == true)
        return callback(true);
        else
        return callback(false);
    },

    getDistanceFromLatLonInKm: function(lat1,lon1,lat2,lon2) {
        var self = this;
        var R = 6371; // Radius of the earth in km
        var dLat = self.deg2rad(lat2-lat1);  // deg2rad below
        var dLon = self.deg2rad(lon2-lon1); 
        var a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(self.deg2rad(lat1)) * Math.cos(self.deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ; 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c; // Distance in km
        return d;
    },

    deg2rad:function(deg) {
        return deg * (Math.PI/180)
    },

    getUniqArrayObjFromKey(arrayObj, key) {
        var alreadyKeyAdded = []
        var uniqArrayObj = [];
        _.each(arrayObj, function (o){
            if(alreadyKeyAdded.indexOf(o[key]) == -1) {
                alreadyKeyAdded.push(o[key]);
                o.nb = 1;
                uniqArrayObj.push(o);
            } else {
                var findObj = _.find(uniqArrayObj, function (uo){
                    return uo[key] == o[key];
                })
                findObj.nb += 1;
            }
        })

        return _.sortBy(uniqArrayObj, function (o){ return o[key]; });
    },

    makeid: function(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }
       return result;
    },

    saveParcoursURL: function (generatedId, selectedParams, callback) {
        $.ajax({
            url: this.rootAPI + "saveParcours.php",
            method:"post",
            data:{id:generatedId, params:JSON.stringify(selectedParams)},
            success: function (response) {
                return callback(response)
            }
        })
    },

    getParcoursParams: function (parcoursId, callback) {
        $.ajax({
            url: this.rootAPI + "getParcours.php",
            method:"post",
            data:{id:parcoursId},
            success: function (response) {
                return callback(response)
            }
        })
    },

    getDistanceFromLatLonInKm: function(lat1,lon1,lat2,lon2) {
      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
      var dLon = this.deg2rad(lon2-lon1); 
      var a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ; 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c; // Distance in km
      return d;
    },

    deg2rad:function(deg) {
        return deg * (Math.PI/180)
    },

    fallbackCopyTextToClipboard: function(text) {
        console.log("fallbackCopyTextToClipboard", text);
      var textArea = document.createElement("textarea");
      textArea.value = text;
      
      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    },

    computeHumanReadableAxisLines:function (maxValue) {
        // Find the magnitude of the maxValue
        const magnitude = Math.floor(Math.log10(maxValue));
        
        // Determine the first digit of the maxValue
        const firstDigit = Math.floor(maxValue / Math.pow(10, magnitude));

        // Determine human-readable interval
        let interval;
        if (firstDigit === 1) {
            interval = 2;
        } else if (firstDigit <= 5) {
            interval = 5;
        } else {
            interval = 10;
        }
        interval *= Math.pow(10, magnitude - 1);

        // Calculate roundedMax and generate axis lines
        const roundedMax = Math.ceil(maxValue / interval) * interval;
        const axisLines = [];
        for (let i = 0; i <= roundedMax; i += interval) {
            axisLines.push(i);
        }

        return axisLines;
    }
});

module.exports = Utils;