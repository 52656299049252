var App = {
    $body:$("body"),

    init: function init() {
        this.isLoaded = false;

        this.typesColors = {
            "vase":"#B93B45",
            "sculpture":"#5FC6D3",
            "mobilier-instrument":"#D4BA2F",
            "bijou":"#3562B8",
            "arme":"#713B8B",
            "jouet-et-sport":"#D46588",
            "momie-sepulture":"#D47E2F",
            "peinture-mosaique":"#166E64",
            "textile":"#57A75B",
            "inconnu":"#D0CABB"
        };

        this.selectedParams = {
            "ventes":[],
            "types":[],
            "vasetypes":[],
            "matieres":[],
            "acteurs":[],
            "idobjet":[],
            "idvente":[]
        };

        this.availableFilters = {
            "types":[],
            "matieres":[],
            "acteurs":[],
            "ventes":[]
        }

        this.selectedTransactions = [];
        this.selectedVentesGrouped = {};
        this.ventesGrouped = {};
        this.availableTransactions = {
            "ventes": [],
            "types": [],
            "matieres": [],
            "acteurs": []
        }

        this.correspondingParamLabels = {
            "types":{},
            "matieres":{},
            "acteurs":{}
        }

        this.$ValeursGroupeContainer = $("#ValeursGroupeContainer");
        this.$App = $("#App");
        this.$groupeBarchart = $(".groupe_barchart");
        this.$matrixchartHead = $(".matrixchart_head");
        this.$matrixchartRows = $(".matrixchart_rows");
        this.$BeeswarmChartTooltip = $("#BeeswarmChartTooltip");
        this.$selectionContainer = $("#selectionContainer");
        this.$BarchartExpandbar = $("#BarchartExpandbar");
        this.$BeeswarmExpandbar = $("#BeeswarmExpandbar");

        this.selectedLang = "fr";
        this.selectedBarchartMode = "average";
        this.maxPrices = {
            "average": 0,
            "min": 0,
            "max": 0
        }

        this.barchartHeight = 20;
        this.barchartOverflowHeight = 300;

        this.maxBarchartPct = 92;
        this.maxMatrixBubbleSize = 75;
        this.minBubbleSize = 8;
        this.bubbleDispValueBottomLimit = 40;

        this.beeswarmWidth = 1030;
        this.beeswarmHeight = 2800;
        this.beeswarmRowHeight = 130;
        this.beeswarmOverflowHeight = 820;
        this.beeswarmMargin = [80, 0, 40, 110];
        this.beeswarmSliderValue = 1;
        this.svg = "";
        this.synthesisTable = [];
        this.differentiateToggle = false;
        this.$differentiateLegend = $(".differentiate_legend");

        this.togglePriceType = "francs";
        this.euroFactor = 0.25;
        this.priceUnit = "Francs";
        this.priceUnitSingle = "Franc";
        this.priceUnitShort = "Fr";

        var Utils = require("utils");
        this.utils = new Utils();

        var HeaderView = require("views/headerView");
        this.headerView = new HeaderView();

        var FooterView = require("views/footerView");
        this.footerView = new FooterView();

        var Router = require("routerValeurs");
        this.router = new Router();

        Backbone.history.start();
    },

    updateFromRouter:function(){
        var self = this;
        if(!this.isRendered){
            this.isRendered = true;
            this.utils.gatherData(function(){
                self.render();
            });
        }
    },

    render:function(){
        this.utils.fillTranslations();
        this.headerView.render();
        this.footerView.render();
        
        this.buildOptions();
        this.bindEvents();

        this.update();
    },

    bindEvents:function(){
        var self = this;

        $(".conversion_toggler .toggler_toggle").on("click", function (){
            if(self.togglePriceType == "francs"){
                self.togglePriceType = "euros";
                self.priceUnit = "Euros";
                self.priceUnitSingle = "Euro";
                self.priceUnitShort = "€";
            } else {
                self.togglePriceType = "francs";
                self.priceUnit = "Francs";
                self.priceUnitSingle = "Franc";
                self.priceUnitShort = "Fr";
            }
            App.update();
        })

        $(".filtreBox").on("click",function(){
            var filtre = $(this).attr("data-filtre");
            if($(".filtreOptionsContainer[data-options='"+filtre+"']").hasClass("display")){
                $(".filtreOptionsContainer").removeClass("display");    
            }else{
                $(".filtreOptionsContainer").removeClass("display");
                $(".filtreOptionsContainer[data-options='"+filtre+"']").addClass("display");    
            }
            return false;
        });

        $(".optionLine").on("click",function(){
            self.selectOneFiltre($(this).find(".optionCheckbox"));
            return false;
        });

        $(".filtreBoxCancelBtn").on("click",function(){
            var filtreID = $(this).parents(".filtreBoxContainer").find(".filtreBox").attr("data-filtre");
            if(filtreID=="vasetypes"){
                App.selectedParams.vasetypes = [];
            }else{
                //_.each(App.selectedParams,function(tabSelectedOption,filtre){
                    App.selectedParams[filtreID] = [];
                //});
            }
            $(".filtreOptionsContainer").removeClass("display");
            App.update();
        });

        $("#rightPanelFooter .cancelFilterBtn").on("click",function(){
            _.each(App.selectedParams,function(tabSelectedOption,filtre){
                    App.selectedParams[filtre] = [];
            });
            App.update();
        });

        $("#rightPanel, #ValeursGroupeContainer").on("click",function(e){
            $(".filtreOptionsContainer").removeClass("display");
        });

        $(".groupe_barchart").on("mouseenter", ".item_bar", function (){
            self.$ValeursGroupeContainer.addClass("ontop");
        })

        $(".groupe_barchart").on("mouseleave", ".item_bar", function (){
            self.$ValeursGroupeContainer.removeClass("ontop");
        })

        $(".valeurs_groupe_chart .filter_bt").on("click", function (){
            self.selectedBarchartMode = $(this).attr("data-mode");
            self.updateBarsWidth();
        })

        this.$matrixchartRows.on("mouseenter", ".cell_bubble", function (){
            self.updateMatrixColHeaders($(this));
        })

        this.$matrixchartRows.on("mouseleave", ".cell_bubble", function (){
            $(".label_col").removeClass("active");
        })

        this.$selectionContainer.on("click", ".item_chip", function (){
            var $chip = $(this);
            self.removeFilterFromChip($chip);
        });

        $("#BarchartExpandbar .expandbar_bt").on("click", function (){
            $("#ValeursBarchart").toggleClass("expanded");
        })

        $("#BeeswarmExpandbar .expandbar_bt").on("click", function (){
            $("#BeeswarmChart").toggleClass("expanded");
        })

        $(".differentiate_toggler").on("click", function (){
            self.differentiateToggle = !self.differentiateToggle;
            self.updateDifferentiateToggle();
        });
    },

    selectOneFiltre:function($checkbox){
        var self = this

        var dataOptionValue = $checkbox.attr("data-option");
        var dataType = $checkbox.parents(".filtreOptionsContainer").attr("data-options");
        myNewParams = App.selectedParams[dataType];

        if(dataOptionValue == "all"){
            App.selectedParams[dataType] = [];
        } else {
            if(App.selectedParams[dataType].includes(dataOptionValue)){
                myNewParams = _.without(App.selectedParams[dataType], dataOptionValue);
            }else{
                myNewParams.push(dataOptionValue);
            }   

            /*
            _.each(App.selectedParams,function(tabSelectedOption,filtre){
                if(dataType != "vasetypes"){
                    App.selectedParams[filtre] = [];
                }
            });
            */

            App.selectedParams[dataType] = myNewParams;
        }

        $(".cancelFilterBtn").addClass("reveal");
        App.update();
    },

    buildOptions:function(){
        var self = this;
        var tabVentes = [];
        var tabTypes = [];
        var tabMatieres = [];
        var tabVases = [];
        var tabActeurs = [];

        this.ventesGrouped = _.groupBy(App.utils.ventes, function (o){
            return _.str.slugify(o.vente_nom);
        })

        _.each(App.utils.ventes,function(vente,i){
            vente.ventes_prix_value_francs = vente.ventes_prix_value;

            if(vente.ventes_prix_value !== null && vente.ventes_prix_value > 0 && !isNaN(vente.vente_date_ts)){

                if(!tabVentes.includes(vente.vente_nom)){
                    tabVentes.push(vente.vente_nom);
                }

                if(!tabTypes.includes(App.utils.objets[vente.id_objet].categorie_picto)){
                    var typeLabel = App.utils.objets[vente.id_objet].categorie_picto;
                    tabTypes.push(typeLabel);
                    var slugTypeLabel = _.str.slugify(typeLabel)
                    self.correspondingParamLabels.types[slugTypeLabel] = {
                        slug: slugTypeLabel,
                        label: typeLabel
                    }
                }

                if(!tabMatieres.includes(App.utils.objets[vente.id_objet].matiere_filtre)&&App.utils.objets[vente.id_objet].matiere_filtre!=null){
                    tabMatieres.push(App.utils.objets[vente.id_objet].matiere_filtre);
                    var slugMatiereLabel = _.str.slugify(App.utils.objets[vente.id_objet].matiere_filtre)
                    self.correspondingParamLabels.matieres[slugMatiereLabel] = {
                        slug: slugMatiereLabel,
                        label: App.utils.objets[vente.id_objet].matiere_filtre
                    }
                }

                if(!tabVases.includes(App.utils.objets[vente.id_objet].type_vase)&&App.utils.objets[vente.id_objet].categorie_picto=="vase"&&App.utils.objets[vente.id_objet].type_vase!=""){
                    tabVases.push(App.utils.objets[vente.id_objet].type_vase);
                }

            }

        });

        var tempTabActeurs=[];
        _.each(App.utils.acteurs,function(acteur,i){
            //if(acteur.acteur_star==1){
                tempTabActeurs.push(acteur)
            //}
        });
        var tempTabActeurs = _.sortBy(tempTabActeurs, function(a){ return parseInt(a.acteur_nom);});


        _.each(tempTabActeurs,function(acteur,i){
            tabActeurs.push(acteur.uk_acteur)
        })

        tabVentes.sort(function(a,b){
            return  parseInt(a.slice(-4)) - parseInt(b.slice(-4));
        });

        _.each(tabVentes,function(venteName,i){
            var html = "<div class='optionLine' data-option='"+_.str.slugify(venteName)+"'><div class='optionCheckbox' data-option='"+_.str.slugify(venteName)+"'></div>"+venteName.replace("Vente ","")+"<span class='option_nb'></span></div>";
            $(".filtreOptionsContainer[data-options='ventes']").append(html);
        });

        self.$differentiateLegend.html("");
        _.each(tabTypes,function(type,i){
            var slugType = _.str.slugify(type);
            var typeString = type.charAt(0).toUpperCase() + type.slice(1);
            var html = "<div class='optionLine' data-option='"+slugType+"'><div class='optionCheckbox' data-option='"+slugType+"'></div><span class='option_label' data-option='"+slugType+"'>"+ typeString +"</span><span class='option_nb'></span></div>";
            $(".filtreOptionsContainer[data-options='types']").append(html);

            self.$differentiateLegend.append("<div class='legend_item'><div class='item_chip' style='background: "+self.typesColors[_.str.slugify(type)]+"'></div>"+typeString+"</div>");
        });

        _.each(tabMatieres,function(matiere,i){
            var html = "<div class='optionLine' data-option='"+_.str.slugify(matiere)+"'><div class='optionCheckbox' data-option='"+_.str.slugify(matiere)+"'></div>"+matiere.charAt(0).toUpperCase() + matiere.slice(1)+"<span class='option_nb'></span></div>";
            $(".filtreOptionsContainer[data-options='matieres']").append(html);
        });
        
        _.each(tabVases,function(typevase,i){
            var html = "<div class='optionLine' data-option='"+_.str.slugify(typevase)+"'><div class='optionCheckbox' data-option='"+_.str.slugify(typevase)+"'></div>"+typevase.charAt(0).toUpperCase() + typevase.slice(1)+"<span class='option_nb'></span></div>";
            $(".filtreOptionsContainer[data-options='vasetypes']").append(html);
        });

        _.each(tabActeurs,function(acteurID,i){
            var acteurName = App.utils.acteurs[acteurID].acteur_nom;
            var html = "<div class='optionLine' data-option='"+_.str.slugify(acteurID)+"'><div class='optionCheckbox' data-option='"+_.str.slugify(acteurID)+"'></div>"+acteurName+"<span class='option_nb'></span></div>";
            $(".filtreOptionsContainer[data-options='acteurs']").append(html);
        });

    },

    update: function (){
        var self = this;

        this.router.updateRoute();

        _.each(App.utils.ventes,function(vente,i){
            if(self.togglePriceType == "euros") vente.ventes_prix_value = vente.ventes_prix_value_francs * self.euroFactor;
            else vente.ventes_prix_value = vente.ventes_prix_value_francs;
        })

        this.updateMenus();
        this.updateSelectedTransactions();

        this.updateVentesBarchart();
        this.updateMatrixChart();
        this.updateBeeswarmChart();
    },

    updateMenus:function(){
        var self = this;

        $(".conversion_toggler").attr("data-toggle", self.togglePriceType);

        $(".optionLine").removeClass("checked");
        $(".optionLine.all").addClass("checked");

        if(App.selectedLang=="fr"){
            $(".selectionNumber").html("(toutes)");
            $(".filtreBox[data-filtre='types'] .selectionNumber").html("(tous)");
            $(".filtreBox[data-filtre='acteurs'] .selectionNumber").html("(tous)");
            $(".filtreBox[data-filtre='vasetypes'] .selectionNumber").html("(tous)");
        }else{
            $(".selectionNumber").html("(all)");
            $(".filtreBox[data-filtre='types'] .selectionNumber").html("(all)");
            $(".filtreBox[data-filtre='acteurs'] .selectionNumber").html("(all)");
            $(".filtreBox[data-filtre='vasetypes'] .selectionNumber").html("(all)");
        }
        $(".filtreBoxCancelBtn").removeClass("active");
        $(".filtreBox").removeClass("filtred"); 

        _.each(App.selectedParams,function(tabSelectedOption,filtre){

            if(tabSelectedOption.length>0){
                $(".filtreOptionsContainer[data-options='"+filtre+"'] .optionLine.all").removeClass("checked");
                $(".filtreBox[data-filtre='"+filtre+"'] .selectionNumber").html("("+tabSelectedOption.length+")");
                $(".filtreBox[data-filtre='"+filtre+"']").parents(".filtreBoxContainer").find(".filtreBoxCancelBtn").addClass("active");  
                $(".filtreBox[data-filtre='"+filtre+"']").addClass("filtred");  
            }

            _.each(tabSelectedOption,function(value,i){
                $(".filtreOptionsContainer[data-options='"+filtre+"'] .optionLine[data-option='"+value+"']").addClass("checked");
            })
        })

        if(_.without(App.selectedParams.types, "vase").length==0&&App.selectedParams.types.includes("vase")){
            $(".filtreBox[data-filtre='vasetypes']").addClass("show");
            $(".filtreBox[data-filtre='types']").addClass("vasetypes");
            $("#typesVaseTitle").addClass("show");
        } else{
            $(".filtreBox[data-filtre='vasetypes']").removeClass("show");
            $(".filtreBox[data-filtre='types']").removeClass("vasetypes");
            $("#typesVaseTitle").removeClass("show");
        }
                
    },

    updateSelectedTransactions:function(){

        var self=this;
        
        var tmpTransactions = [];
        var tmpMatrixTransactions = [];

        if(App.selectedParams.ventes.length==0&&App.selectedParams.types.length==0&&App.selectedParams.matieres.length==0&&App.selectedParams.acteurs.length==0){
            _.each(App.utils.ventes,function(vente,i){
                if(vente.ventes_prix_value !== null && vente.ventes_prix_value > 0 && !isNaN(vente.vente_date_ts)){
                    tmpTransactions.push(vente);
                    tmpMatrixTransactions.push(vente);
                }
            });
        }else{
            _.each(App.utils.ventes,function(vente,i){
                var toAppend = true;
                var toAppendMatrix = true;
                var hasFalse = 0;
                var hasFalseMatrix = 0;

                if(vente.ventes_prix_value !== null && vente.ventes_prix_value > 0 && !isNaN(vente.vente_date_ts)){
                    _.each(App.selectedParams,function(tabSelectedOption,filtre){
                         if(tabSelectedOption.length>0){
                            if(filtre == "matieres"){
                                var valueToCheck = _.str.slugify(App.utils.objets[vente.id_objet].matiere_filtre);
                                if(tabSelectedOption.includes(valueToCheck)){
                                } else {
                                    hasFalse +=1;
                                }
                            } else if (filtre == "ventes"){
                                var valueToCheck = _.str.slugify(vente.vente_nom);
                                if(tabSelectedOption.includes(valueToCheck)){
                                } else {
                                    hasFalse += 1;
                                    hasFalseMatrix += 1;
                                }
                            } else if (filtre=="acteurs"){
                                var acheteurUK = _.str.slugify(vente.uk_acteur_vente_acheteur).toString();
                                var vendeurUK = _.str.slugify(vente.uk_acteur_vente_vendeur).toString();
                                if(tabSelectedOption.includes(acheteurUK)||tabSelectedOption.includes(vendeurUK)){
                                } else {
                                    hasFalse += 1;
                                    hasFalseMatrix += 1;
                                }
                            } else if (filtre == "types"){
                                if(App.selectedParams.vasetypes.length > 0){
                                    var valueToCheck = _.str.slugify(App.utils.objets[vente.id_objet].type_vase);
                                    if(App.selectedParams.vasetypes.includes(valueToCheck)){
                                    } else{
                                        hasFalse += 1;
                                    }
                                }else{  
                                    var valueToCheck = _.str.slugify(App.utils.objets[vente.id_objet].categorie_picto);    
                                    if(tabSelectedOption.includes(valueToCheck)){
                                    } else {
                                        hasFalse += 1;
                                    }
                                }
                            }
                        }
                    })
                } else {
                    toAppend = false;
                    toAppendMatrix = false;
                }

                if(hasFalse > 0) toAppend = false;
                if(toAppend) tmpTransactions.push(vente);

                if(hasFalseMatrix > 0) toAppendMatrix = false;
                if(toAppendMatrix) tmpMatrixTransactions.push(vente);
            })
    
        }

        this.availableFilters = {
            "types":[],
            "matieres":[],
            "acteurs":[],
            "ventes":[]
        }

        /*
        _.each(tmpTransactions, function (transacObj){
            console.log("transacObj", transacObj);
            self.availableFilters
        })
        */

        App.selectedTransactions = _.sortBy(tmpTransactions, function (o){
            return o.vente_date_ts;
        })

        App.selectedMatrixTransactions = _.sortBy(tmpMatrixTransactions, function (o){
            return o.vente_date_ts;
        })

        this.selectedVentesGrouped = _.groupBy(App.selectedTransactions, function (o){
            return o.vente_nom;
        })

        self.updateDetails();
        self.updateItemsAvailability();
    },

    updateItemsAvailability: function (){
        var self = this;

        this.availableTransactions = {
            "ventes": { groupedTransactions:{} },
            "types": { groupedTransactions:{} },
            "matieres": { groupedTransactions:{} },
            "acteurs": { groupedTransactions:{} }
        }

        _.each(App.utils.ventes,function(vente,i){
            if(vente.ventes_prix_value !== null && vente.ventes_prix_value > 0 && !isNaN(vente.vente_date_ts)){
                var venteMatiere = _.str.slugify(App.utils.objets[vente.id_objet].matiere_filtre);
                var venteType = _.str.slugify(App.utils.objets[vente.id_objet].categorie_picto);
                var venteActeurs = [_.str.slugify(vente.uk_acteur_vente_acheteur).toString(), _.str.slugify(vente.uk_acteur_vente_vendeur).toString()];
                var venteName = _.str.slugify(vente.vente_nom);

                var hasMatiere = false;
                if(self.selectedParams.matieres.length == 0 || (self.selectedParams.matieres.length >= 1 && self.selectedParams.matieres.includes(venteMatiere))){
                    hasMatiere = true;
                }

                var hasType = false;
                if(self.selectedParams.types.length == 0 || (self.selectedParams.types.length >= 1 && self.selectedParams.types.includes(venteType))){
                    hasType = true;
                }

                var hasVente = false;
                if(self.selectedParams.ventes.length == 0 || (self.selectedParams.ventes.length >= 1 && self.selectedParams.ventes.includes(venteName))){
                    hasVente = true;
                }

                var hasActeur = false;
                var intersectActeurs = _.intersection(self.selectedParams.acteurs, venteActeurs);
                if(self.selectedParams.acteurs.length == 0 || (self.selectedParams.acteurs.length >= 1 && intersectActeurs.length >= 1)){
                    hasActeur = true;
                }

                if(hasMatiere && hasType && hasActeur){
                    if(self.availableTransactions.ventes.groupedTransactions[venteName] == undefined){
                        self.availableTransactions.ventes.groupedTransactions[venteName] = 0;
                    }
                    self.availableTransactions.ventes.groupedTransactions[venteName] += 1;
                }
                if(hasMatiere && hasType && hasVente){
                    if(self.availableTransactions.acteurs.groupedTransactions[venteActeurs[0]] == undefined){
                        self.availableTransactions.acteurs.groupedTransactions[venteActeurs[0]] = 0;
                    }
                    if(self.availableTransactions.acteurs.groupedTransactions[venteActeurs[1]] == undefined){
                        self.availableTransactions.acteurs.groupedTransactions[venteActeurs[1]] = 0;
                    }
                    self.availableTransactions.acteurs.groupedTransactions[venteActeurs[0]] += 1;
                    self.availableTransactions.acteurs.groupedTransactions[venteActeurs[1]] += 1;
                }
                if(hasMatiere && hasActeur && hasVente){
                    if(self.availableTransactions.types.groupedTransactions[venteType] == undefined){
                        self.availableTransactions.types.groupedTransactions[venteType] = 0;
                    }
                    self.availableTransactions.types.groupedTransactions[venteType] += 1;
                }
                if(hasType && hasActeur && hasVente){
                    if(self.availableTransactions.matieres.groupedTransactions[venteMatiere] == undefined){
                        self.availableTransactions.matieres.groupedTransactions[venteMatiere] = 0;
                    }
                    self.availableTransactions.matieres.groupedTransactions[venteMatiere] += 1;
                }
            }
        })

        $(".optionLine").addClass("disabled");
        $(".optionLine.all").removeClass("disabled");
        $(".option_nb").html("(0)");

        _.each(this.availableTransactions, function (availObj, filterID){
            _.each(availObj.groupedTransactions, function (groupNb, groupID){
                $(".optionLine[data-option='"+groupID+"']").removeClass("disabled");
                $(".optionLine[data-option='"+groupID+"'] .option_nb").html("("+groupNb+")");
            })
        })
    },

    updateDetails:function(){
        var self = this;
        App.venteFiltreActivated = false;
        if(App.selectedParams.ventes.length==0&&App.selectedParams.types.length==0&&App.selectedParams.matieres.length==0&&App.selectedParams.acteurs.length==0){
            $(".cancelFilterBtn").removeClass("reveal");
            $("#selectedTransactionsContainer").empty();
            $("#transactionNumber").html(App.selectedTransactions.length);
            $("#selectedTransactionsContainer").append('<div class="selectionBox"><div class="selectionColor"></div><span class="selectionItemName">Toutes les transactions&nbsp;'+'<span class="selectionItemNumber">('+App.selectedTransactions.length+')</span></span></div>')
        }else{
            $("#selectedTransactionsContainer").empty();
            $("#transactionNumber").html(App.selectedTransactions.length);
            
            /*
            if(App.selectedParams.ventes.length>0){

                var venteUnique = _.groupBy(App.selectedTransactions,function(vente){
                        return vente.vente_nom
                });

                var categories = {};

                _.each(venteUnique,function(items,venteName){
                    var newName = venteName.replace("Vente ","");
                    var tabId = [];
                    _.each(items,function(item,i){
                        tabId.push(item.id_objet)
                    })
                    categories[newName] = tabId;
                });

                App.venteFiltreActivated = true;
            
            } else if(App.selectedParams.types.length>0){

                if(App.selectedParams.vasetypes.length > 0){
                    proprietyObj = "type_vase";
                } else{
                    proprietyObj = "categorie_picto";
                }

                var typeUnique = {};

                _.each(App.selectedTransactions,function(vente){
                    typeUnique[App.utils.objets[vente.id_objet][proprietyObj]] = [];
                })

                _.each(App.selectedTransactions,function(vente){
                    typeUnique[App.utils.objets[vente.id_objet][proprietyObj]].push(vente.id_objet);
                })

                var categories = {};

                _.each(typeUnique,function(items,typeName){
                    var newName = typeName.charAt(0).toUpperCase() + typeName.slice(1)
                    categories[newName] = items;
                });

            } else if (App.selectedParams.matieres.length>0){

                var typeUnique = {};

                _.each(App.selectedTransactions,function(vente){
                    typeUnique[App.utils.objets[vente.id_objet].matiere_filtre] = [];
                })

                _.each(App.selectedTransactions,function(vente){
                    typeUnique[App.utils.objets[vente.id_objet].matiere_filtre].push(vente.id_objet);
                })

                var categories = {};

                _.each(typeUnique,function(items,typeName){
                    var newName = typeName.charAt(0).toUpperCase() + typeName.slice(1)
                    categories[newName] = items;
                });

            } else if (App.selectedParams.acteurs.length>0){
                    
                var typeUnique = {};

                _.each(App.selectedParams.acteurs,function(selectedActeurs,filtre){
                    typeUnique[selectedActeurs]= [];
                });

                _.each(App.selectedTransactions,function(vente){
                    if(typeof typeUnique[vente.uk_acteur_vente_acheteur] != "undefined"){
                        typeUnique[vente.uk_acteur_vente_acheteur].push(vente.id_objet);
                    }
                    if(typeof typeUnique[vente.uk_acteur_vente_vendeur] != "undefined"){
                        typeUnique[vente.uk_acteur_vente_vendeur].push(vente.id_objet);
                    }
                });

                var tempCategories = {};

                 _.each(typeUnique,function(items,idActeurs){
                    var newName = App.utils.acteurs[idActeurs].acteur_nom;
                    tempCategories[newName] = items;
                });

                keysSorted = Object.keys(tempCategories).sort(function(a,b){return tempCategories[a].length-tempCategories[b].length}).reverse();

                var categories = {}

                _.each(keysSorted,function(key,i){
                    categories[key] = tempCategories[key];
                });

            }

            App.filtredObj = categories;

            _.each(categories,function(tabOfItem,categorieName){
                if(App.selectedParams.acteurs.length>0){
                    var acteurUK = _.find(App.utils.acteurs,function(a){return a.acteur_nom == categorieName}).id;
                    var acteurURL = "acteurs.php#"+App.selectedLang+"/[]/[]/[]/nb_objets/desc/"+acteurUK;
                    var html = '<div class="selectionBox"><div class="selectionColor"></div><span class="selectionItemName">'+categorieName+'&nbsp'+'<span class="selectionItemNumber">('+tabOfItem.length+')</span></span><a href="'+acteurURL+'" target="_blank"><div class="selectionItemExtLink"></div></a></div>';
                } else{
                    var html = '<div class="selectionBox"><div class="selectionColor"></div><span class="selectionItemName">'+categorieName+'&nbsp'+'<span class="selectionItemNumber">('+tabOfItem.length+')</span></span></div>';    
                }
                $("#selectedTransactionsContainer").append(html);
            });
            */

            _.each(App.selectedParams, function (paramItems, paramID){
                if(paramID == "ventes"){
                    _.each(paramItems, function (itemSlug){
                        var itemNb = 0;
                        var itemLabel = self.ventesGrouped[itemSlug][0].vente_nom;
                        if(self.selectedVentesGrouped[itemLabel] != undefined){
                            itemNb = self.selectedVentesGrouped[itemLabel].length;
                        }
                        var html = '<div class="selectionBox"><div class="selectionColor"></div><span class="selectionItemName">'+itemLabel+'&nbsp'+'<span class="selectionItemNumber">('+itemNb+')</span></span></div>'; 
                        $("#selectedTransactionsContainer").append(html);
                    })
                } else if(paramID == "types" || paramID == "matieres") {
                    if(paramItems.length > 0){
                        var html = '<div class="selectionBox param"><span class="param_label">' + paramID + ' : </span>';
                        _.each(paramItems, function (itemSlug){
                            var itemLabel = itemSlug;
                            if(self.correspondingParamLabels[paramID][itemSlug] !== undefined){
                                itemLabel = self.correspondingParamLabels[paramID][itemSlug].label
                            }
                            html += '<span class="item_chip" data-param="'+paramID+'" data-slug="'+itemSlug+'">'+itemLabel+' X</span>';
                        })
                        html += '</div>';
                        $("#selectedTransactionsContainer").append(html);
                    }
                } else if(paramID == "acteurs"){
                    if(paramItems.length > 0){
                        var html = '<div class="selectionBox param"><span class="param_label">' + paramID + ' : </span>';
                        _.each(paramItems, function (acteurID){
                            var itemLabel = App.utils.acteurs[acteurID].acteur_nom;
                            itemLabel = itemLabel.split(" (")[0];
                            html += '<span class="item_chip" data-param="acteurs" data-slug="'+acteurID+'">'+itemLabel+' X</span>';
                        })
                        html += '</div>';
                        $("#selectedTransactionsContainer").append(html);
                    }
                }
            })
        }
    },

    updateVentesBarchart: function (){
        var self = this;

        this.$groupeBarchart.empty();

        this.maxPrices = {
            "average": 0,
            "min": 0,
            "max": 0
        }

        var maxPriceObj = _.max(App.selectedTransactions, function (o){
            return o.ventes_prix_value;
        });

        console.log("maxPriceObj", maxPriceObj);
        this.maxPrices.max = maxPriceObj.ventes_prix_value;

        var currentHeight = 0;
        var totalHeight = this.barchartHeight * _.size(this.selectedVentesGrouped);
        if(totalHeight > this.barchartOverflowHeight){
            this.$BarchartExpandbar.addClass("displayed");
        } else {
            this.$BarchartExpandbar.removeClass("displayed");
        }

        this.synthesisTable = [];

        console.log("this.selectedVentesGrouped", this.selectedVentesGrouped);

        _.each(this.selectedVentesGrouped, function (group, venteNom){
            var averagePrice = 0;
            var sumPrice = 0;
            var incPrice = 0;
            var minPrice = 10000000000;
            var maxPrice = 0;

            _.each(group, function (groupItem){
                if(groupItem.ventes_prix_value !== null && groupItem.ventes_prix_value > 0){
                    sumPrice += groupItem.ventes_prix_value;
                    incPrice += 1;
                    if(groupItem.ventes_prix_value < minPrice) minPrice = groupItem.ventes_prix_value;
                    if(groupItem.ventes_prix_value > maxPrice) maxPrice = groupItem.ventes_prix_value;
                }
            })

            averagePrice = (sumPrice / incPrice);
            if(averagePrice > self.maxPrices.average) self.maxPrices.average = averagePrice;
            if(minPrice > self.maxPrices.min) self.maxPrices.min = minPrice;

            var isOverflowing = "";
            if(currentHeight >= self.barchartOverflowHeight) isOverflowing = "isoverflow";
            var $barchartItem = $("<div class='barchart_item "+isOverflowing+"' data-average='"+averagePrice+"' data-max='"+maxPrice+"' data-min='"+minPrice+"'><div class='item_label'>"+group[0].vente_nom+"</div><div class='item_bar_wrapper'><div class='item_bar'><div class='bar_tooltip'><div class='tooltip_title'>"+group[0].vente_nom+"</div><div class='tooltip_mode'></div><div class='tooltip_value'></div></div></div></div></div>");
            self.$groupeBarchart.append($barchartItem);

            currentHeight += self.barchartHeight;

            self.synthesisTable.push({
                vente: venteNom,
                min: minPrice,
                max: maxPrice,
                average: averagePrice,
                sum: sumPrice
            })
        })

        var $barchartAxis = $("<div class='barchart_axis'></div>");
        self.$groupeBarchart.append($barchartAxis);

        this.updateBarsWidth();
    },

    updateBarsWidth: function (){
        var self = this;

        console.log("updateBarsWidth", self.selectedBarchartMode);

        $(".valeurs_groupe_chart .filter_bt").removeClass("selected");
        $(".valeurs_groupe_chart .filter_bt[data-mode='"+self.selectedBarchartMode+"']").addClass("selected");

        if(self.selectedBarchartMode == "tableau"){
            $("#ValeursBarchart").attr("data-view", "tableau");
            self.updateBarchartTableau();
            return;
        }
        console.log("ici ??");

        $("#ValeursBarchart").attr("data-view", "notableau");
        var modeLabel = "moyen";
        if(self.selectedBarchartMode == "min") modeLabel = "minimal";
        else if (self.selectedBarchartMode == "max") modeLabel = "maximum";
        $(".barchart_item .tooltip_mode").html("Prix " + modeLabel);

        var maxModeValue = self.maxPrices[self.selectedBarchartMode];
        var axisValues = this.utils.computeHumanReadableAxisLines(maxModeValue);
        var lastAxisValue = axisValues[axisValues.length - 1];

        var barchartItems = this.$groupeBarchart.find(".barchart_item");
        _.each(barchartItems, function (barchartItem){
            var $barchartItem = $(barchartItem);
            var widthValue = $barchartItem.attr("data-"+self.selectedBarchartMode);
            widthValue = parseFloat(widthValue);

            var $bar = $barchartItem.find(".item_bar");
            var pctWidth = (widthValue * self.maxBarchartPct) / lastAxisValue;
            if(pctWidth < 0.5) pctWidth = 0.5;
            $bar.css("width", pctWidth + "%");

            let formattedValue;
            if (widthValue < 1 && widthValue.toFixed(2) != widthValue.toFixed(1)) {
                console.log("ici");
                // If widthValue is less than 0 and has more than 1 decimal, show 2 decimals
                formattedValue = _.str.numberFormat(widthValue, 2, ".", " ");
            } else {
                // Otherwise, check if it's an integer or has 1 decimal
                formattedValue = widthValue % 1 === 0 
                    ? _.str.numberFormat(widthValue, 0, ".", " ")  // Integer case
                    : _.str.numberFormat(widthValue, 1, ".", " "); // 1 decimal case
            }

            var priceUnit = self.priceUnit
            if(widthValue < 2) priceUnit = self.priceUnitSingle;
            $bar.find(".tooltip_value").html(formattedValue.replace(".",",") + " " + priceUnit);
        })

        //update Axis
        var $barchartAxis = $(this.$groupeBarchart.find(".barchart_axis"));
        $barchartAxis.empty();
        _.each(axisValues, function (axisValue){

            var axisDispValue = _.str.numberFormat(axisValue, 0, ".", " ")
            console.log("axisValue", axisValue);
            let decimalPart = axisValue.toString().split(".")[1];
            if(axisValue == 0){
                axisDispValue = "0";
            } else if(Number.isInteger(axisValue)){
                axisDispValue = _.str.numberFormat(axisValue, 0, ".", " ")
            }
            // Check if the number has more than 2 decimal places, round to 2
            else if (decimalPart && decimalPart.length > 1) {
                // If there are more than 1 decimal place, round to 2 decimals
                axisDispValue = axisValue.toFixed(2);
            } else {
                // Otherwise, display with 1 decimal
                axisDispValue = axisValue.toFixed(1);
            }
            
            var $axisLine = $("<div class='axis_line'><span class='line_value'>"+ axisDispValue.replace(".",",") +"</span></div>");
            var axisLeftPct = (axisValue * self.maxBarchartPct) / lastAxisValue;
            $axisLine.css("left", axisLeftPct + '%');
            $barchartAxis.append($axisLine);
        })
        self.$groupeBarchart.append($barchartAxis);
    },

    updateBarchartTableau: function (){
        var self = this;
        $("#ValeursSynthesisTable tbody").html("");

        _.each(this.synthesisTable, function (rowObj){
            let minPrice = parseFloat(rowObj.min.toFixed(1)) % 1 === 0 
                ? _.str.numberFormat(rowObj.min, 0, ".", " ") 
                : _.str.numberFormat(rowObj.min, 1, ".", " ");
            //var minPrice = _.str.numberFormat(rowObj.min, 0, ".", " ");

            let maxPrice = parseFloat(rowObj.max.toFixed(1)) % 1 === 0 
                ? _.str.numberFormat(rowObj.max, 0, ".", " ") 
                : _.str.numberFormat(rowObj.max, 1, ".", " ");
            //var maxPrice = _.str.numberFormat(rowObj.max, 0, ".", " ");

            let avgPrice = parseFloat(rowObj.average.toFixed(1)) % 1 === 0 
                ? _.str.numberFormat(rowObj.average, 0, ".", " ") 
                : _.str.numberFormat(rowObj.average, 1, ".", " ");
            //var avgPrice = _.str.numberFormat(rowObj.average, 0, ".", " ");

            let sumPrice = parseFloat(rowObj.sum.toFixed(1)) % 1 === 0 
                ? _.str.numberFormat(rowObj.sum, 0, ".", " ") 
                : _.str.numberFormat(rowObj.sum, 1, ".", " ");
            //var sumPrice = _.str.numberFormat(rowObj.sum, 0, ".", " ");
            $("#ValeursSynthesisTable tbody").append("<tr><td class='vente'>"+rowObj.vente+"</td><td>"+avgPrice.replace(".",",")+" "+self.priceUnitShort+"</td><td>"+minPrice.replace(".",",")+" "+self.priceUnitShort+"</td><td>"+maxPrice.replace(".",",")+" "+self.priceUnitShort+"</td><td>"+sumPrice.replace(".",",")+" "+self.priceUnitShort+"</td></tr>");
        })
    },

    updateMatrixChart: function (){
        var self = this;
        var matrixObj = {};
        var typesObj = {};

        if(this.selectedMatrixTransactions.length > 0){
            _.each(this.selectedMatrixTransactions, function (transacObj){
                var artObj = App.utils.objets[transacObj.id_objet];
                var objPrix = transacObj.ventes_prix_value;
                //console.log("artObj", artObj);
                var matiereLabel = artObj.matiere_filtre;
                if(matiereLabel == null) matiereLabel = "inconnue";

                if(objPrix !== null && objPrix > 0){
                    if(matrixObj[matiereLabel] == undefined){
                        matrixObj[matiereLabel] = {
                            label: matiereLabel,
                            types:{
                                average: {
                                    label: "average",
                                    sum: 0,
                                    avgPrice: 0,
                                    inc: 0
                                }
                            }
                        }
                    }

                    matrixObj[matiereLabel].types.average.sum += objPrix;
                    matrixObj[matiereLabel].types.average.inc += 1;

                    if(matrixObj[matiereLabel].types[artObj.categorie_picto] == undefined){
                        matrixObj[matiereLabel].types[artObj.categorie_picto] = {
                            label: artObj.categorie_picto,
                            sum: 0,
                            avgPrice: 0,
                            inc: 0
                        } 
                    }

                    matrixObj[matiereLabel].types[artObj.categorie_picto].sum += objPrix;
                    matrixObj[matiereLabel].types[artObj.categorie_picto].inc += 1;

                    if(typesObj[artObj.categorie_picto] == undefined){
                        typesObj[artObj.categorie_picto] = {
                            label: artObj.categorie_picto
                        }
                    }
                }    
            });

            var maxPrice = 0;
            _.each(matrixObj, function (matiereObj, matiereLabel){
                _.each(matiereObj.types, function (typeObj){
                    typeObj.avgPrice = typeObj.sum / typeObj.inc;
                    if(typeObj.avgPrice > maxPrice) maxPrice = typeObj.avgPrice;
                })
            });

            var sortedMatieres = _.sortBy(matrixObj, function (o){
                return -o.types.average.avgPrice;
            })

            var sortedTypes = _.sortBy(sortedMatieres[0].types, function (o){
                return -o.avgPrice;
            })

            var sortedTypesLabels = _.map(sortedTypes, function(o){
                return o.label;
            })

            var typesObjLabels = _.map(typesObj, function(o){
                return o.label;
            })

            var missingTypesLabels = _.difference(typesObjLabels, sortedTypesLabels);
            _.each(missingTypesLabels, function (typeLabel){
                sortedTypes.push({
                    label: typeLabel
                });
            })

            this.renderMatrixChart(matrixObj, sortedMatieres, sortedTypes, maxPrice);
        } else{
            self.$matrixchartHead.empty();
            self.$matrixchartRows.empty();
        }
    },

    renderMatrixChart: function (matrixObj, sortedMatieres, sortedTypes, maxPrice){
        var self = this;

        self.$matrixchartHead.empty();
        self.$matrixchartHead.append("<div class='head_cell'><span class='label_col' data-type='average'>Prix moyen</span></div>");
        self.$matrixchartRows.empty();

        sortedTypes = _.filter(sortedTypes, function (o){
            return o.label != "average";
        })

        _.each(sortedTypes, function (typeObj){
            var $headCell = $("<div class='head_cell'><span class='label_col' data-type='"+typeObj.label+"'>"+typeObj.label+"</span></div>")
            self.$matrixchartHead.append($headCell);
        });

        var avgFirstSortedTypes = JSON.parse(JSON.stringify(sortedTypes));
        avgFirstSortedTypes.unshift({
            label: "average"
        });

        var maxAreaBubble = Math.PI * Math.pow((self.maxMatrixBubbleSize / 2), 2);

        _.each(sortedMatieres, function (matiereObj, incMatiere){
            var $matiereRow = $("<div class='matiere_row'></div>");
            var $rowLabel = $("<div class='row_label'><span class='label_col' data-matiere='"+matiereObj.label+"'>"+matiereObj.label+"</span></div>");
            var $rowCells = $("<div class='row_cells'></div>");

            var slugMatiere = _.str.slugify(matiereObj.label);
            var isInactiveMatiere = false;

            if(self.selectedParams.matieres.length > 0 && self.selectedParams.matieres.indexOf(slugMatiere) == -1){
                isInactiveMatiere = true;
            }

            _.each(avgFirstSortedTypes, function (typeObj, incType){
                var slugType = _.str.slugify(typeObj.label);
                var isInactiveType = false;
                if(self.selectedParams.types.length > 0 && self.selectedParams.types.indexOf(slugType) == -1){
                    isInactiveType = true;
                }

                var corresObj = matrixObj[matiereObj.label].types[typeObj.label];
                var $cell = $("<div class='body_cell' data-type='"+typeObj.label+"'></div>");
                if(corresObj != undefined){

                    let formattedValue = corresObj.avgPrice % 1 === 0 
                        ? _.str.numberFormat(corresObj.avgPrice, 0, ".", " ") 
                        : _.str.numberFormat(corresObj.avgPrice, 1, ".", " ");
                    //var dispPrice = _.str.numberFormat(corresObj.avgPrice, 0, ".", " ");

                    var bubbleArea = (corresObj.avgPrice * maxAreaBubble) / maxPrice;
                    //var bubbleSize = (corresObj.avgPrice * self.maxMatrixBubbleSize) / maxPrice;
                    var bubbleSize = (Math.sqrt(bubbleArea / (Math.PI))) * 2;
                    if(bubbleSize < self.minBubbleSize) bubbleSize = self.minBubbleSize;

                    var dispBottom = "";
                    if(bubbleSize < self.bubbleDispValueBottomLimit) dispBottom = "bottom";

                    var inactive = "";
                    if(isInactiveMatiere || isInactiveType) inactive = "inactive";

                    var $cellBubble = $("<div class='cell_bubble "+inactive+"' data-matiere='"+matiereObj.label+"' data-type='"+typeObj.label+"' style='width: " + bubbleSize + "px; height: " + bubbleSize + "px;'><div class='bubble_value "+dispBottom+"'>"+ formattedValue.replace(".",",")  +" "+self.priceUnitShort+"</div></div>")

                    var modeStr = typeObj.label + " en " + matiereObj.label;
                    var tooltipPos = "left";
                    if(incType <= 1) tooltipPos = "right";

                    var priceUnit = self.priceUnit
                    if(corresObj.avgPrice < 2) priceUnit = self.priceUnitSingle;

                    $cellBubble.append("<div class='cell_tooltip "+tooltipPos+"'><div class='tooltip_title'>Toutes les ventes</div><div class='tooltip_mode'>"+modeStr+"</div><div class='tooltip_value'>"+formattedValue.replace(".",",") +" "+priceUnit+"</div></div>");

                    $cell.append($cellBubble);

                    
                } else {
                    $cell.append("<div class='cell_bubble default'></div>");
                }

                $rowCells.append($cell);
            })

            $matiereRow.append($rowLabel)
                        .append($rowCells);
            self.$matrixchartRows.append($matiereRow);
        })
    },

    updateMatrixColHeaders: function ($cellBubble){
        var bubbleMatiere = $cellBubble.attr("data-matiere");
        var bubbleType = $cellBubble.attr("data-type");
        $(".label_col[data-type='"+bubbleType+"']").addClass("active");
        $(".label_col[data-matiere='"+bubbleMatiere+"']").addClass("active");
    },

    calculateStep: function(max) {
        let step;
        if (max >= 10000) {
            step = 250;
        } else if (max >= 1000 && max < 10000) {
            step = 50;
        } else {
            step = 10;
        }
        return step;
    },

    updateDifferentiateToggle: function (){
        if(this.differentiateToggle){
            $("#BeeswarmWrapper").addClass("active");
            $("#rightPanel #filtresContainer").addClass("differentiate");
        } else {
            $("#BeeswarmWrapper").removeClass("active");
            $("#rightPanel #filtresContainer").removeClass("differentiate");
        }

        this.updateBeeswarmChartColors();
    },

    updateBeeswarmChartColors: function (){
        var self = this;

        self.svg
          .selectAll(".circ") // Select all existing circles
          .attr("fill", function (d) {
            if(self.differentiateToggle){
                var slugCateg = _.str.slugify(App.utils.objets[d.id_objet].categorie_picto);
                return self.typesColors[slugCateg];
            } else {
                return "#E63312";
            }
          });

    },

    updateBeeswarmChart: function (fromSlider){
        var self = this;

        if(!fromSlider){
            this.beeswarmSliderValue = self.maxPrices.max;
        }

        var sliderValueDisp = _.str.numberFormat(this.beeswarmSliderValue, 0, ".", " ") 
        $("#SliderRangeValue").html(sliderValueDisp + " "+self.priceUnitShort);

        var beeswarmStep = this.calculateStep(self.maxPrices.max);

        $( "#slider-range-min" ).slider({
          range: "min",
          value: self.beeswarmSliderValue,
          min: 0,
          step: beeswarmStep,
          max: self.maxPrices.max,
          stop: function( event, ui ) {
            self.beeswarmSliderValue = ui.value;
            self.updateBeeswarmChart(true);
          }
        });
        
        $("#BeeswarmChartSVG").empty();

        this.$BeeswarmExpandbar.removeClass("displayed");
        if(this.selectedTransactions.length > 0){
            var nbGroupes = _.size(this.selectedVentesGrouped);
            this.beeswarmHeight = self.beeswarmMargin[3] + (this.beeswarmRowHeight * nbGroupes);

            if(this.beeswarmHeight > this.beeswarmOverflowHeight){
                this.$BeeswarmExpandbar.addClass("displayed");
            }

          this.svg = d3
            .select("#BeeswarmChartSVG")
            .append("svg")
            .attr("height", this.beeswarmHeight)
            .attr("width", this.beeswarmWidth);


            // TEXT STROKE FILTER
            // Append defs to the SVG
            const defs = this.svg.append("defs");

            // Append filter to the defs
            const filter = defs.append("filter")
                .attr("id", "whiteOutlineEffect")
                .attr("color-interpolation-filters", "sRGB");

            // Append feMorphology to the filter
            filter.append("feMorphology")
                .attr("in", "SourceAlpha")
                .attr("result", "MORPH")
                .attr("operator", "dilate")
                .attr("radius", 2);

            // Use feFlood to flood the dilated area with the desired color
            filter.append("feFlood")
                .attr("flood-color", "#F5EDDD")
                .attr("result", "COLOR");

            // Use feComposite to clip the color to the dilated area
            filter.append("feComposite")
                .attr("in", "COLOR")
                .attr("in2", "MORPH")
                .attr("operator", "in")
                .attr("result", "OUTLINE");

            // Append feMerge to the filter
            const feMerge = filter.append("feMerge");
            feMerge.append("feMergeNode").attr("in", "OUTLINE");
            feMerge.append("feMergeNode").attr("in", "SourceGraphic");

            var dataBeeswarm2 = JSON.parse(JSON.stringify(App.selectedTransactions));

          //data.then((dataBeeswarm) => {
            let ventes = Array.from(new Set(dataBeeswarm2.map((d) => d.vente_nom)));

            const xAccessor = (d) => d.ventes_prix_value;

            let xScale = d3
              .scaleSqrt()
              //      .scaleLog()
              .domain([0, self.beeswarmSliderValue])
              .range([self.beeswarmMargin[0], self.beeswarmWidth - self.beeswarmMargin[2]]);
            //.base(10);

            let yScale = d3
              .scaleBand()
              .domain(ventes)
              .range([self.beeswarmMargin[3], self.beeswarmHeight - self.beeswarmMargin[1]]);

            let color = d3
              .scaleOrdinal()
              .domain(ventes)
              .range(["skyblue", "darkslategrey"]);
            let nombre = d3.extent(dataBeeswarm2.map((d) => +1));
            let size = d3.scaleSqrt().domain(nombre).range([1, 2]);

            /*
            var tooltip = d3
              .select("body")
              .append("div")
              .attr("class","beesawrm_tooltip")
              .style("position", "absolute")
              .style("z-index", "10")
              .style("visibility", "hidden")
              .text("a simple tooltip");
            */

            let yAxisGenerator = d3.axisLeft().scale(yScale);
            const yAxis = self.svg
                    .append("g")
                    .call(yAxisGenerator)
                    .attr("class", "axis y-axis")
                    .attr("data-axis", "y")
                    //.style("transform", "translateX(200px) translateY(-60px)")

            let xAxisGenerator = d3.axisTop().scale(xScale);
            const xAxis = self.svg
              .append("g")
              .call(xAxisGenerator)
              .attr("class", "axis x-axis")
              .style("transform", `translateY(30px)`);

            self.svg
              .selectAll(".circ")
              .data(dataBeeswarm2)
              .enter()
              .append("circle")
              .attr("class", "circ")
              //.attr("stroke", "none")
              .attr("fill", function (d){
                    return "#E63312";                
                })
              .attr("r", (d) => size(1))
              .attr("cx", (d) => xScale(d.ventes_prix_value))
              .attr("cy", (d) => yScale(d.vente_nom))
              .on("mouseover", function (d) {
                self.updateBeeswarmTooltipContent(d.id_objet, d.ventes_prix_value, d.vente_nom)
                self.updateBeeswarmTooltipPosition(d.x, d.y);
                /*
                tooltip.text(d.ventes_prix_value + ", " + d.vente_nom); // you can format the text as you need
                return tooltip.style("visibility", "visible");
                */
              })
              /*
              .on("mousemove", function () {
                self.updateBeeswarmTooltipPosition(d3.event.pageX, d3.event.pageY);
                return tooltip
                  .style("top", d3.event.pageY - 10 + "px")
                  .style("left", d3.event.pageX + 10 + "px");
              })
              */
              .on("mouseout", function () {
                self.hideBeeswarmTooltip();
                /*
                return tooltip.style("visibility", "hidden");
                */
              })
              .on("click", function (d){
                const url = "story.php#fr/" + d.id_objet +"/creation"; // Modify as per your needs
                window.open(url, '_blank'); 
              })

            let simulation = d3
              .forceSimulation(dataBeeswarm2)
              .force("x", d3.forceX((d) => xScale(d.ventes_prix_value)).strength(0.4))
              .force("y", d3.forceY((d) => yScale(d.vente_nom)).strength(1))
              .force(
                "collide",
                d3.forceCollide((d) => size(1) * 1.3)
              )
              .alphaDecay(0)
              .alpha(1)
              .on("tick", tick);

            function tick() {
              d3.selectAll(".circ")
                .attr("cx", (d) => d.x)
                .attr("cy", (d) => d.y);
            }

            let init_decay = setTimeout(function () {
              //console.log("hello console");
              simulation.alphaDecay(0.1);
            }, 500);

            this.svg.select('.y-axis')
              .selectAll('g line')
              .attr('x2', '100%')

              this.svg.select('.x-axis')
              .selectAll('g line')
              .attr('y2', '100%')
          //});

            yAxis.selectAll('.tick text')
              .attr('data-text', function(d) {
                // 'd' is the tick value, you can use it if needed
                return d; // Replace 'someValue' with the value you want to set
              });

            const yAxisCopy = this.svg.select(".y-axis").node().cloneNode(true);

            // Create a D3 selection from the cloned node, modify its attributes/class, and append it
            const newAxisGroup = d3.select(yAxisCopy)
                .attr("class", "y-axis y-axis-copy")

            this.svg.node().appendChild(yAxisCopy);
        }

        this.updateBeeswarmChartColors();

    },

    updateBeeswarmTooltipContent: function(idObjet, ventePrix, venteNom){
        var self = this;
        this.$BeeswarmChartTooltip.find(".tooltip_title").html(venteNom);
        this.$BeeswarmChartTooltip.find(".tooltip_mode").html(App.utils.objets[idObjet].nom_objet);

        //var dispPrix = _.str.numberFormat(ventePrix, 0, ".", " ");
        let formattedValue = ventePrix % 1 === 0 
                ? _.str.numberFormat(ventePrix, 0, ".", " ") 
                : _.str.numberFormat(ventePrix, 1, ".", " ");

        var priceUnit = self.priceUnit
        if(ventePrix < 2) priceUnit = self.priceUnitSingle;

        this.$BeeswarmChartTooltip.find(".tooltip_value").html(formattedValue.replace(".",",")  + " "+ priceUnit);
        this.$BeeswarmChartTooltip.addClass("displayed");
    },

    updateBeeswarmTooltipPosition: function (posX, posY){
        this.$BeeswarmChartTooltip.css("left", (posX + 20) + "px")
                                .css("top", (posY) + "px")
    },

    hideBeeswarmTooltip: function (){
        this.$BeeswarmChartTooltip.removeClass("displayed");
    },

    removeFilterFromChip: function ($chip){
        var dataSlug = $chip.attr("data-slug");
        var dataParam = $chip.attr("data-param");

        this.selectedParams[dataParam] = _.without(this.selectedParams[dataParam], dataSlug);
        App.update();
    }
}

module.exports = App;
window.App = App;