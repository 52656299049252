var App = {
    $body:$("body"),

    init: function init() {
        this.isLoaded = false;
        this.$cardExtLink = $(".card_extbt");
        this.$cardName = $(".card_name");
        this.$cardImage = $(".card_image");

        this.$App = $("#App");
        this.selectedLang = "fr";
        this.averageObjectPrice = 0;
        this.squareMaxSize = 105;
        this.squareMinSize = 10;

        var Utils = require("utils");
        this.utils = new Utils();

        var HeaderView = require("views/headerView");
        this.headerView = new HeaderView();

        var FooterView = require("views/footerView");
        this.footerView = new FooterView();

        var Router = require("routerValeurSingle");
        this.router = new Router();

        this.gatherData();
    },

    gatherData:function(){
        this.utils.gatherData(function(){
            Backbone.history.start();
        });
    },

    updateFromRouter:function(){
        var self = this;
        if(this.isLoaded) location.reload();
        else{
            this.isLoaded = true;
            this.render();
        }
    },

    render:function(){
        this.utils.fillTranslations();
        this.headerView.render();
        this.footerView.render();
        this.bindEvents();
        this.update();
    },

    bindEvents:function(){
        var self = this;
    },

    update: function (){
        var self = this;

        this.router.updateRoute();

        this.$cardExtLink.attr("href", "story.php#" + this.selectedLang + "/" + App.objID);
        this.$cardName.html(App.utils.objets[App.objID].nom_objet);
        $("#ArianItemOeuvreLabel").html(App.utils.objets[App.objID].nom_objet);

        var objImg = App.utils.objets[App.objID].media;

        App.utils.imageExists2(App.utils.objets[App.objID].image_exists, function(imgExists){
            if(imgExists){
                self.$cardImage.attr("src", App.utils.objets[App.objID].media);
            } else{
                var defaultImage = "img/collection/default/"+_.str.slugify(App.utils.objets[App.objID].categorie_picto)+"-";
                if(App.utils.objets[App.objID].matiere_filtre !== null && App.utils.objets[App.objID].matiere_filtre !== ""){
                    defaultImage += (_.str.slugify(App.utils.objets[App.objID].matiere_filtre) + ".svg");
                }else{
                    defaultImage += "defaut.svg";
                }
                self.$cardImage.attr("src", defaultImage);
            }
        });
        
        this.updateTimeseries();
        this.updateComparisons();
    },

    updateTimeseries: function (){
        var sumPrices = 0;
        var dataPrices = _.map(App.utils.objets[App.objID].sortedVentes, function (o){
            if(o.ventes_prix_value != null) sumPrices += o.ventes_prix_value;
            return o.ventes_prix_value;
        })

        this.averageObjectPrice = (sumPrices / dataPrices.length);

        var chartCategories = _.map(App.utils.objets[App.objID].sortedVentes, function (o){
            return o.vente_date;
        })

        console.log("dataPrices", dataPrices);

        this.myHighchart = new Highcharts.chart({
            chart: {
                renderTo:"OeuvreSingleTimeseries",
                type: "line",
                backgroundColor: "transparent"
            },
            title: {
                text: ""
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: chartCategories,
                gridLineWidth: 2,
                lineWidth:0,
                gridLineColor: "#D4D4D4",
                lineColor: "#D4D4D4",
                gridLineDashStyle: "Dot",
                tickmarkPlacement: "on"
            },
            yAxis: {
                gridLineWidth: 0,
                gridLineDashStyle: "Solid",
                gridLineColor: "#F5EDDD",
                lineColor: "#F5EDDD",
                lineWidth: 0,
                title:{
                    enabled: false
                },
                labels:{
                    enabled: false
                }
            },
            legend:{
                enabled: false
            },
            tooltip: {
                enabled: false
            },
            plotOptions:{
                series:{
                    color: "#E63312",
                    dataLabels:{
                        enabled: true,
                        useHTML: true,
                        formatter: function (){
                            var unit = "francs";
                            if(this.y < 2) unit = "franc";
                            return "<div class='point_block'><span class='point_value'>"+ this.y +" "+unit+"</span></div>"
                        }
                    }
                }
            },
            series: [{
                data: dataPrices
            }]
        });
    },

    updateComparisons: function (){
        var unit = "Francs";
        if(this.averageObjectPrice < 2) unit = "Franc";
        $(".chart_square.object .square_label").html(App.utils.objets[App.objID].nom_objet);
        $(".chart_square.object .square_value").html(this.averageObjectPrice.toFixed(1).replace(".",",") + " " + unit);

        console.log("updateComparisons", App.utils.objets[App.objID]);
        var objectType = App.utils.objets[App.objID].categorie_picto;
        var objectMatiere = App.utils.objets[App.objID].matiere_filtre;

        var allObjectsType = _.filter(App.utils.objets, function (o){
            return o.categorie_picto == objectType;
        })

        var allObjectsMatiere = _.filter(App.utils.objets, function (o){
            return o.matiere_filtre == objectMatiere;
        })

        console.log("allObjectsType", allObjectsType);
        console.log("allObjectsMatiere", allObjectsMatiere);

        var sumPriceType = 0;
        var incPriceType = 0;
        _.each(allObjectsType, function (o){
            var sumObjPrice = 0;
            var allPrices = _.map(o.sortedVentes, function (s){
                if(s.ventes_prix_value != null){
                    sumObjPrice += s.ventes_prix_value;
                }
                return s.ventes_prix_value;
            })

            allPrices = _.without(allPrices, null);
            if(allPrices.length > 0){
                sumPriceType += (sumObjPrice/allPrices.length);
                incPriceType += 1;
            }
        })

        var averagePriceType = sumPriceType / incPriceType;
        var unit2 = "Francs";
        if(averagePriceType < 2) unit2 = "Franc";
        $(".chart_square.comp.type .square_value").html(averagePriceType.toFixed(1).replace(".",",") + " " + unit2);
        console.log("averagePriceType", averagePriceType)


        var sumPriceMatiere = 0;
        var incPriceMatiere = 0;
        _.each(allObjectsMatiere, function (o){
            var sumObjPrice = 0;
            var allPrices = _.map(o.sortedVentes, function (s){
                if(s.ventes_prix_value != null){
                    sumObjPrice += s.ventes_prix_value;
                }
                return s.ventes_prix_value;
            })

            allPrices = _.without(allPrices, null);
            if(allPrices.length > 0){
                sumPriceMatiere += (sumObjPrice/allPrices.length);
                incPriceMatiere += 1;
            }
        })

        var averagePriceMatiere = sumPriceMatiere / incPriceMatiere;
        var unit3 = "Francs";
        if(averagePriceMatiere < 2) unit3 = "Franc";
        $(".chart_square.comp.matiere .square_value").html(averagePriceMatiere.toFixed(1).replace(".",",") + " " + unit3);
        console.log("averagePriceMatiere", averagePriceMatiere)

        var maxValue = 0;
        if((this.averageObjectPrice > averagePriceType) && (this.averageObjectPrice > averagePriceMatiere)){
            maxValue = this.averageObjectPrice;
        } else {
            if(averagePriceType > averagePriceMatiere){
                maxValue = averagePriceType;
            } else{
                maxValue = averagePriceMatiere;
            }
        }

        var objSquareSize = (this.averageObjectPrice * 100) / maxValue;
        if(objSquareSize < this.squareMinSize) objSquareSize = this.squareMinSize;
        $(".chart_square.object").css("width", objSquareSize + "px")
                                .css("height", objSquareSize + "px");

        var typeSquareSize = (averagePriceType * 100) / maxValue;
        if(typeSquareSize < this.squareMinSize) typeSquareSize = this.squareMinSize;
        $(".chart_square.comp.type").css("width", typeSquareSize + "px")
                                .css("height", typeSquareSize + "px");

        var matiereSquareSize = (averagePriceMatiere * 100) / maxValue;
        if(matiereSquareSize < this.squareMinSize) matiereSquareSize = this.squareMinSize;
        $(".chart_square.comp.matiere").css("width", matiereSquareSize + "px")
                                .css("height", matiereSquareSize + "px");
    }
}

module.exports = App;
window.App = App;