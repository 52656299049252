var App = {
    $body:$("body"),

    init: function init() {
        this.$App = $("#App");
        this.selectedLang = "fr";

        var Utils = require("utils");
        this.utils = new Utils();

        var HeaderView = require("views/headerView");
        this.headerView = new HeaderView();

        var FooterView = require("views/footerView");
        this.footerView = new FooterView();

        var Router = require("router");
        this.router = new Router();

        Backbone.history.start();
    },

    updateFromRouter:function(){
        var self = this;
        this.utils.gatherData(function(){
            self.render();
        });
    },

    render:function(){
    	this.utils.fillTranslations();
    	this.headerView.render();
        this.footerView.render();
    	this.bindEvents();
        this.createDiscoverCase();
        this.utils.displayMenuActiveBtn("discover");
        
    },

    bindEvents:function(){
    	var self = this;

        $("#modaleCloseBtn").on("click",function(){
            $("#discoverContainer").removeClass("fade");
            $("#discoverModale").removeClass("show");
        });

        

        $(".discover_btn").on("click", function(){
            obj = $(this).attr("data-obj");
            location.href = "story.php#"+App.selectedLang+"/"+obj;
        });
    },

    createDiscoverCase:function(){
        /* créé et affiche toutes les cases des objets stars */
        var self = this;
        var stars = this.utils.getStarObjects();
        stars = _.shuffle(stars);
        _.each(stars,function(star,i){
            var url = star.media;
            $("#discoverContainer").append("<div class='discoverCase' style='background-image:url("+url+")' data-position='"+i+"' data-objet='"+star.id_objet+"'><p class='discoverCaseLabel'>"+star.nom_objet+"</p></div>")
        });

        $(".discoverCase").on("click",function(){
            var obj_id = $(this).attr("data-objet");
            self.showDescription(obj_id);
        });

        _.each(stars,function(star,i){
            self.revealStar(i)
        });


    },

    revealStar:function(i){
        
        setTimeout(function(){
            $(".discoverCase[data-position='"+i+"']").addClass("reveal");    
        },i*150);
    },

    showDescription:function(id){
        /* au clic sur l'une des cases, affiche la description et le lien pour voir l'histoire */
        var clickedObj = _.find(this.utils.objets, function (obj) { return obj.id_objet == id;})
        if(App.selectedLang=="fr"){
            var descToShow = clickedObj.description;
        }else{
            var descToShow = clickedObj.description_en;
        }
        var url = clickedObj.media;
        var name = clickedObj.nom_objet;

        $("#modaleTitle").html(name);
        $("#modaleImg").css("background-image","url("+url+")");
        $("#discoverModaleTxt").html(descToShow);
        $("#discoverModale").addClass("show");
        $("#discoverContainer").addClass("fade");
        $(".discover_btn").attr("data-obj",clickedObj.id_objet);

        /*$(".discoverCase").on("click",function(){
            var obj_id = $(this).attr("data-objet");
            self.showDescription(obj_id);
        });*/

        setTimeout(function(){
            $("#App").on("click",function(){
                $("#discoverContainer").removeClass("fade");
                $("#discoverModale").removeClass("show");
                $("#App").off();
            }); 
        },100)

        $("html, body").animate({ scrollTop: 0 }, 600);
        return false;

    },

};

module.exports = App;
window.App = App;